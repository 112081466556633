@import '@theme';

.legend-score-tooltip-content{
    
    &__section{
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items:center;
        color: #1A2024;

        &:first-child{
            margin-top: 0;
        }
    }

    &__name{
        font-family: $ff_medium;
    }
}
