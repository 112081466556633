@import '@theme';

.card-dining-preference-header{
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__description {
		text-align: right;
		font-size: 14px;
		font-family: $ff_normal;
	}
}

.card-dining-preference-content{
	padding: 25px 24px 16px 24px; 
}


.dining-preference-gauge {
	border-bottom: 1px dashed #DADADA;
	display: flex;
	align-items: center;
	font-size: 16px;
	padding-top: 4px;
	padding-bottom: 4px;

	&__name{
		color: #4A4A4A;
		flex-basis: 130px;
	}

	&__meter {
		flex: 1 0 auto;
	}
}

.gauge-line-meter {
	height: 13px;
	background: #32C1E9;
	border-radius: 16px;
	width: 0;
}