@import '@theme';

.Toggle {
    $root: &;
    cursor: pointer;

    &__icon {
        .is-switch & {
            display: block;
            width: 32px;
            height: 16px;
            border-radius: 8px;
            position: relative;
            background: $gray_bd;
            transition: background 300ms;

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #ffffff;
                position: absolute;
                top: 2px;
                left: 2px;
                transition: transform 300ms;
            }
        }

        .is-check & {
            position: relative;
            width: 16px;
            height: 16px;
            background: #f8f8f8;
            box-shadow: 0 0 0 1px #d9d9d9 inset;
            border-radius: 3px;
            transition: background 300ms, color 300ms;
            color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.is-checked {

        &.is-switch {
            #{$root}__icon {
                background: $lightblue;

                &:before {
                    transform: translateX(calc(100% + 4px));
                }
            }
        }

        &.is-check {
            #{$root}__icon {
                background: $lightblue;
                box-shadow: 0 0 0 1px $lightblue inset;
                color: #fff;
            }
        }
    }
}