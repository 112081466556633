@import '@theme';

.ActionsBar {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: $footer_height;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 24px;

    border-top: 2px solid #babbbd;
    box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.1);
    z-index: 3;

    & > * {
        flex: 1 1 33%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
            justify-content: flex-end;
        }

        &:first-child {
            justify-content: flex-start;
        }
    }
}