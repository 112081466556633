@import '@theme';

.Select {
    position: relative;
    $root: &;
    width: 230px;
    height: 32px;
    user-select: none;
    $icon_size: 32px;
    font-size: 12px;
    $triangle_size: 5px;

    &.width-l {
        width: 300px;
    }

    &.is-disabled {
        pointer-events: none;
    }

    &__thumb {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding-block: 4px;
        padding-inline: 10px $icon_size;
        border: 1px solid $gray_bd;
        display: flex;
        align-items: center;
        cursor: pointer;

        #{$root}.is-opened & {
            border-color: $lightblue;
        }

        &:hover {
            #{$root}__arrow {
                color: $lightblue;
            }
        }
    }

    &__thumb-inner {
        flex: 0 1 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__label {
        position: absolute;
        top: 0;
        left: 10px;
        transform: translateY(-50%);
    }

    &__arrow {
        position: absolute;
        top: 50%;
        margin-top: -16px;
        right: 0;
        width: $icon_size;
        height: $icon_size;
        transition: transform 300ms;
        display: flex;
        align-items: center;
        justify-content: center;

        #{$root}.is-opened & {
            transform: rotate(180deg);
        }
    }

    &__clear-button {
        position: absolute;
        bottom: 100%;
        right: 0;
        color: $red;
        font-size: 12px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__dropdown {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
        padding-block: 8px;
        background: #fff;
        z-index: 2;
        box-shadow: 0 0 20px rgba(#000, .16);
        border-radius: 4px;
        overflow-x: hidden;
        color: #000;
        opacity: 0;
        pointer-events: none;
        font-size: 12px;
        
        #{$root}.is-opened & {
            opacity: 1;
            pointer-events: all;
        }

        &.w-s {
            width: 100px;
        }

        &.w-xl {
            width: 420px;
        }

        &.w-auto {
            width: auto;
        }

        &.has-search {
            height: 200px;
            padding-block: 0;
            padding-top: 31px;
            overflow: visible;
        }

        &.pos-r {
            left: auto;
            right: 0;
        }
    }

    &__dropdown-triangle {
        position: absolute;
        width: 0;
        height: 0;
        border-width: $triangle_size;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #fff;
        z-index: 3;
        top: 100%;
        right: $icon_size * 0.5 - $triangle_size;

        opacity: 0;
        pointer-events: none;

        #{$root}.is-opened & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__option {
        width: 100%;
        padding: 6px 10px;
        min-height: 26px;
        cursor: pointer;
        font-family: $font_normal;
        transition: background-color 300ms, color 300ms;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
            font-style: normal;
            background-color: yellow;
            color: #4f4f4f;
        }

        b {
            font-family: $ff_bold;
        }

        &:hover {
            background: $gray_bg;
        }

        &#{$root}__sup-option {
            cursor: default;
            font-family: $font_bold;
            background: transparent;
        }

        &.is-active {
            &:not(.is-checkbox) {
                color: #fff;
                background: $lightblue;
            }

            &.is-checkbox {
                //color: #fff;
                //background: $lightblue;

                &:before {
                    content: '✓';
                }
            }
        }

        &.is-checkbox {
            padding-left: 30px;

            &:before {
                content: '';
                width: 16px;
                height: 16px;
                display: block;
                position: absolute;
                top: 50%;
                margin-top: -8px;
                left: 8px;
                border-radius: 4px;
                border: 1px solid;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
            }
        }

        &.is-disabled {
            pointer-events: none;
            opacity: 0.6;
        }

        &--button {
            position: relative;
            display: inline-flex;
            width: auto;
            margin-bottom: 8px;
            color: #2d4658;
            font-size: 12px;
            padding: 4px 10px;
            background: #f2f2f2;
            border-radius: 4px;
            line-height: 14px;
            white-space: nowrap;
            text-transform: uppercase;
            min-height: 22px;

            &:hover {
                background-color: $lightblue;
                color: #fff;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }


    &__search {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 32px;
        padding: 5px 10px;
        background: #fff;
        z-index: 2;

        input {
            width: 100%;
            height: 100%;
            padding-left: 20px;
            font-size: 12px;
            border: 0;
            border-bottom: 1px solid;
        }
    }

    &__search-icon {
        width: 14px;
        height: 14px;
        position: absolute;
        left: 12px;
        top: 9px;
    }
}