@use 'sass:math';
@import '@theme';

@keyframes to-left-7 {
    from {
        transform: translateX(#{math.div(100%, 7)});
    }
    to {
        transform: translateX(0);
    }
}

@keyframes to-right-7 {
    from {
        transform: translateX(-#{math.div(100%, 7)});
    }
    to {
        transform: translateX(0);
    }
}
@keyframes to-left-6 {
    from {
        transform: translateX(#{math.div(100%, 6)});
    }
    to {
        transform: translateX(0);
    }
}

@keyframes to-right-6 {
    from {
        transform: translateX(-#{math.div(100%, 6)});
    }
    to {
        transform: translateX(0);
    }
}

.TilesCarousel {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    $animation_duration: 800ms;

    &__viewport {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: $tile-height;
        margin-top: 2 * $tile_half_indent;
        perspective: 50em;
        z-index: 1;

        &.items-5 {
            flex: 0 0 5 * $tile-with-indents-width - 2 * $tile_half_indent;
        }

        &.items-4 {
            flex: 0 0 4 * $tile-with-indents-width - 2 * $tile_half_indent;
        }
    }

    &__item {
        opacity: 1;
        transition: opacity $animation_duration, transform $animation_duration;
        position: absolute;
        width: $tile-with-indents-width;
        top: 0;
        left: - $tile_half_indent;

        @for $i from 0 through 8 {
            &.item-#{$i} {
                transform: translateX(#{$tile-with-indents-width * ($i - 1)});
                &.is-last {
                    transform: translateX(#{$tile-with-indents-width * ($i - 1)}) rotateY(30deg);
                }

                &.is-first {
                    transform: translateX(#{$tile-with-indents-width * ($i - 1)}) rotateY(-30deg);
                }
            }
        }

        &.is-first, &.is-last {
            pointer-events: none;
            opacity: 0;
            backface-visibility: hidden;
        }

        &.is-after-first, &.is-first {
            transform-origin: 100% 0;
        }

        &.is-before-last, &.is-last {
            transform-origin: 0 0;
        }
    }

    &__nav {
        position: relative;
        height: 36px;
        border-radius: 50%;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        flex: 0 0 36px;

        &:hover {
            &:before {
                background: #e4e4e4;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 2;
            border-radius: 50%;
            border: 2px solid #000;
            transition: background-color 300ms;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            height: $tile-height + 2 * $tile_half_indent;
            transform: translateY(-50%);
            width: $tile-width;
            background: $body_bg;
            //background: red;
            pointer-events: none;
            z-index: 0;
        }

        & > * {
            position: relative;
            width: 20px;
            height: 20px;
            z-index: 2;
        }
    }

    &__prev {
        margin-right: 4px;

        &:after {
            right: $tile_half_indent;
        }
    }

    &__next {
        margin-left: 4px;

        &:after {
            left: $tile_half_indent;
        }
    }
}