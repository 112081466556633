@import '@theme';

.StarsRangeSlider {

    .RangeSlider__mark {
        color: #a2a1a1;
        transform: translateY(10px);

        &.is-active {
            color: $lightblue;
        }
    }
}