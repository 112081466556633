@import '@theme';

.FiltersPopup {
    box-sizing: border-box;
    width: 1024px;
    height: 600px;
    background-color: #fff;
    padding: 8px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    z-index: 910;
    color: #4a4a4a;
    box-shadow: 0 4px 12px rgba(0,0,0,.1);

    &.is-opened {
        opacity: 1;
        pointer-events: all;
    }

    &, &.position-default {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0s ease 500ms, opacity 500ms ease 0s;
    }

    &.position-header {
        top: $header_height - 10px;
        transform: translate(-50%, 0);
        transition: all 500ms ease;
    }

    &.position-footer {
        top: calc(100% - #{$footer_height});
        left: 20px;
        transform: translate(0, -100%);
        transition: all 500ms ease;
    }

    &__scroll-container {
        height: calc(100% - 60px);
        overflow-x: hidden;
        padding-left: 16px;
        padding-right: 16px;
        flex-grow: 1;
    }

    &__reset-button {
        color: $red;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__options-button {
        color: $lightblue;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__options-button-separator {
        color: $lightblue;
        padding-left: 10px;
        padding-right: 10px;
    }

    &__field-separator {
        height: 32px;
        width: 32px;
        background: $gray_bg;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        margin-inline: 16px;
        padding-bottom: 2px;
        position: relative;

        &.is-centered {
            align-self: center;
        }

    }

    &__line-separator {
        position: absolute;
        width: 1px;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $gray-bd;
    }

    &__block {
        position: relative;
        transition: opacity 300ms;

        &:after {
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(#4f4f4f, 0.2);
            opacity: 0;
            box-shadow: 0 0 5px 10px rgba(#4f4f4f, 0.2);
            border-radius: 4px;
            transition: opacity 300ms;
            z-index: 2;
        }

        &.is-descend {
            opacity: 0.3;
            &:after {
                opacity: 1;
            }
            &:hover {
                opacity: 1;
                &:after {
                    opacity: 0;
                }
            }
        }
    }

    &__separator {
        height: 1px;
        width: 100%;
        background: $gray_bd;
        margin-block: 24px;
    }

    &__footer {
        height: 60px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #ccc;

        > div {
            display: flex;
            align-items: center;
        }
    }
}

.FiltersPopupOverlay {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(#fff, 0.2);
    backdrop-filter: blur(5px);
    pointer-events: none;
    opacity: 0;
    z-index: 3;

    &.is-shown {
        opacity: 1;
        pointer-events: all;
    }
}