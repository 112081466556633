.button-clipboard-copy {
	position: absolute;
	right: 0;
	top: 0;
	background-color: #ffffff;
	border: 1px solid #D7DCE0;
	box-sizing: border-box;
	box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
	border-radius: 0 6px 6px 0;	
	transform: translateX(100%);
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 0;
	will-change: width;
	cursor: pointer;
	overflow: hidden;
	min-width: 15px;
	transition: min-width 0.3s ease 0s;
	$root: &;

	&__more{
		background-color: #d1d7dc;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		position: relative;
		transition: opacity 0.3s ease 0s;
		opacity: 1;

		&:before,
		&:after{
			content: "";
			position: absolute;
			background-color: #d1d7dc;
			width: 6px;
			height: 6px;
			border-radius: 50%;
		}

		&:before{
			top: -14px;
		}

		&:after{
			bottom: -14px;
		}

	}

	&__button {
		border: 0px;
		height: 100%;
		position: absolute;
		width: 100%;
		right: 0;
		top: 0;
		opacity: 0;
		transition: opacity 0.4s ease 0s;

		.caption{
			display: block;
			font-size: 11px;
			line-height: 11px;
		} 
	}

	&.is-open{
		min-width: 40px;
		transition: min-width 0.3s ease 0s;

		.button-clipboard-copy__more{
			opacity: 0;
			pointer-events:none;
		}

		.button-clipboard-copy__button{
			opacity: 1;
		}
	}

	&.is-busy {
		.button-clipboard-copy__button {
			cursor: wait;
		}

		&.is-open {
			.button-clipboard-copy__button {
				animation: pulse 0.8s ease infinite;
			}
		}
	}

}

@keyframes pulse {
	20% {
		opacity: 0;
	}
	100% {
		opacity: 0.6;
	}
}