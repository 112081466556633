@import '@theme';

.metric-main-tooltip{
    width: 520px;
    display: flex;
    align-items: flex-start;

    &__description {
        flex-basis: 250px;
        max-width: 250px;
        font-size: 14px;
        line-height: 17px;
        border-right: 1px solid #4F4F4F;
        box-sizing: border-box;
        padding-right: 10px;

        .important{
            font-family: 'DIN Pro Bold';
            color: #FFC107;
        }
    }
}

.metric-main-tooltip-legend {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 10px;
    flex-grow: 1;

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing:border-box;

        &:first-child{
            padding-top: 0;
        }

        &:last-child{
            padding-bottom: 0;
        }
    }

    &__name {
        padding-left: 10px;
        font-size: 14px;
        line-height: 26px;
    }


    &__score {
        display: flex;
        flex-basis: 70px;
        font-size: 12px;

        .star-rating-meter {
            display: flex;
            justify-content: flex-end;
            width: 70px;
            flex-basis: 70px;

            .icon-star {
                margin-right: 1px;
                margin-left: 1px;
            }
        }
    }

    .display{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #FFFFFF;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--top-performer{
            background-color: $deepgreen;
        }

        &--above-average{
            background-color: $lightblue;
        }

        &--average{
            background-color: $purple;
        }

        &--below-average{
            background-color: $brown;
        }

        &--weak-performer{
            background-color: $red;
        }

    }
}

.metric-description-tooltip{
    padding-bottom: 3px;
    font-family: $ff_light;
    font-size: 14px;
    line-height: 17px;


    &__item{
        padding-top: 1px;
        padding-bottom: 1px;

        .name {
            width: 180px;
            display: inline-block;
            text-align: right;
            font-family: $ff_bold;
            padding-right: 5px;
        }
    }
}

.metric-score-tooltip{
    width: 620px;
    font-size: 14px;
    line-height: 17px;

    &__content{
        font-size: 14px;
    }

    &__bottom{
        border-top: 1px solid #293034;
        padding-top: 7px;
        margin-top: 10px;
        padding-bottom: 3px;
    }

    .name,
    .caption{
        font-size: 14px;
        font-family: 'DIN Pro Bold';
        text-transform: uppercase;
    }

    .name{
        color: #ffffff;
    }

    .caption{
        color: #FFC107;
    }

    .viability-list{
        background: rgba(196, 196, 196, 0.1);
        border-radius: 8px;
        padding-top: 6px;
        padding-bottom: 6px;

        margin-top: 10px;
        font-size: 13px;
        line-height: 20px;
    }

    .viability-item{
        padding: 0px 8px 1px 8px;

        &__name{
            text-transform: uppercase;
        }

        &.is-selected{
            background: #3F4549;

            .viability-item__name{
                font-family: 'DIN Pro Bold';
            }
        }
    }
}

