@import '@theme';

.Footer {
    background: $header_bg;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;

    .Wrapper {
        height: $footer_height;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}