@import '@theme';

.card-benchmark {
	background-color: #ffffff;
	box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.1);
	position: relative;
	flex: 0 1 100%;
	margin: 0 4px;

	&__caption {
		font-size: 14px;
		line-height: 22px;
		color: #545454;
		text-align: center;
		text-transform: uppercase;
	}

	&__box {
		color: #ffffff;
		padding: 8px 10px;
		position: relative;
		margin-top: 2px;
		box-sizing: border-box;

		&--concept {
			background-color: $lightblue;
			min-height: 40px;
		}

		&--median {
			background-color: #8F8F8F;
		}
	}

	&__val {
		color: #ffffff;
		font-size: 22px;
		line-height: 22px;
		height: 22px;
		left: 5px;
		min-width: 60px;
		position: absolute;
		text-align: right;
		top: 50%;
		transform: translateY(-50%);
	}

	&__desc {
		padding-left: 60px;
		font-size: 11px;
		line-height: 12px;
	}

	&__chart {
		margin-top: 5px;
		font-size: 10px;
		width: 100%;
	}
}

.benchmark-box {
	position: relative;
	transition: 0.2s ease 0s;
	visibility: hidden;
	opacity: 0;
	transform: translateY(-30px);
	z-index: 3;
	margin-top: 20px;

	&.is_active {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.card-benchmark-header{
	display: flex;
	align-items: center;
	justify-content: space-between;


	&__description {
		text-align: right;
		line-height: 16px;
		font-size: 14px;
		font-family: $ff_normal;
	}
}

.card-benchmark-content {
	padding: 16px 24px;
	display: flex;
	flex-grow: 1;
	align-items: flex-end;

	&--full-width{
		.card-benchmark-chart{
			width: 100%;
		}
	}

	&__column{
		flex: 0 0 33.33%;
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding-left: 9px;
		padding-right: 9px;
	}


}

.card-benchmark-chart {
	font-family: $ff_medium;
	color: #4A4A4A;
	position: relative;
	width: 230px;

	.recharts-cartesian-axis-ticks{
		.recharts-cartesian-axis-tick{
			opacity: 0;

			&:first-child,
			&:last-child{
				opacity: 1;
			}
		}

	}

	&__bottom{
		text-align: center;
		background-color: rgba(49, 53, 74, 0.05);
		color: #141831;
		padding-top: 5px;
		padding-bottom: 5px;
		line-height: 14px;

		.caption {
			font-family: $ff_light;
		}

		.name{
			font-family: $ff_bold;
		}

		.value{
			font-size: 10px;
		}
	}
}

.card-benchmark-chart-point{
	position: absolute;
	width: 22px;
	height: 22px;
	background-color: white;
	z-index: 1;
	transform: translate(-50%, -50%);
	box-shadow: 4.57267px 4.57267px 16.0043px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	border: 3.4295px solid #FE944D;
	box-sizing: border-box;

	transition: all 0.3s ease 0s;

	opacity: 0;

	&.is-active{
		opacity: 1;
	}

	&:before{
		content: "";
		display: block;
		width: 1px;
		height: 15px;
		position: absolute;
		top: -3px;
		transform: translateY(-100%) translateX(-50%);
		background: #2d343e;
		left: 50%;
	}

	&__tooltip {
		font-family: $ff_normal;
		position: absolute;
		background: #2D343E;
		border-radius: 62px;
		color: #ffffff;
		font-size: 11px;
		line-height: 12px;
		width: 66px;
		text-align: center;
		padding-bottom: 3px;
		padding-top: 2px;
		left: 50%;
		transform: translateX(-50%);
		bottom: 28px;

		.value{
			font-family: $ff_medium;
		}
	}
}

.card-benchmark-legend {
	display: flex;
	align-items: center;
	color: #ffffff;
	padding: 8px 10px;

	&--concept{
		background-color: #32c1e9;
		margin-top: 5px;
	}

	&--median{
		background-color: #8F8F8F;
		margin-top: 2px;
	}

	&__value {
		font-size: 22px;
		line-height: 22px;
		flex-basis: 60px;
		text-align: right;
		box-sizing: border-box;
		padding-right: 5px;
	}

	&__description {
		font-size: 11px;
		line-height: 12px;
	}
}
