@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@theme';

.card-target-audiences-content {
	padding-bottom: 16px;
	position: relative;
}


.table-target-audiences {
	position: relative;
	width: 100%;
	max-width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	background-color: transparent;
	font-size: 14px;
	line-height: 18px;
	font-family: inherit;


	.table-target-audiences-head-cell{		
		text-align: center;
		vertical-align: middle;

		font-family: $ff_medium;
		font-weight: 400;

		padding-top: 9px;
		padding-bottom: 9px;
		color: #fff;
		font-size: 14px;
		background-color: #4a4a4a;
	}


	.table-head{
		&--sticky {
			.table-target-audiences-head-cell{
				position: sticky;
				top: calc($platform-navigation-height + $platform-header-height + 80px);
				z-index: 2;
		}
	}
		
		.table-target-audiences-head-cell{
			&:last-child{
				padding-right: 36px;
			}
		}
	}

	.table-target-audiences-cell{
		&:first-child{
			position: relative;
			left: 20px;
		}

		&:last-child{
			position: relative;
			right: 20px;
		}
	}
}

.table-target-audiences-cell{

	padding: 7px 5px;
	font-family: inherit;
	font-style: normal;
	background-color: transparent;
	transition: background .2s ease 0s;
	border-bottom: 1px solid #e0e0e0;
	color: #000;
	padding-top: 3px;
	padding-bottom: 3px;

	text-align: center;
	vertical-align: middle;


	&--total{
		padding-top: 18px;
		padding-bottom: 10px;
		color: #4A4A4A;
		font-family: $ff_bold;
		text-transform: uppercase;
	}

	&--value{
		width: 10%;
	}


	&--name{
		text-align: left;
		padding-left: 10px;

	}

	&--base{
		text-transform: uppercase;
		background-color: #F2F4F8;
	}

	&--group{
		font-family: $ff_medium;
		text-align: left;
		background: #F2F4F8;
		text-transform: uppercase;
		padding-top: 3px; 
		padding-bottom: 3px; 
	}
	
	&--type{
		position: relative;
		font-family: $ff_medium;
		background-color: #4A4A4A;
		color: #FFFFFF;
		padding-top: 8px;
		padding-bottom: 8px;
	}

}

.card-target-audiences-expander{

	&__table {
		position: relative;
		transition: max-height 0.3s ease 0s;

		&:before{
			content: "";
			position: absolute;
			bottom: 0;
			height: 85px;
			width: 100%;
			background: linear-gradient(rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
			transition: opacity 0.5s ease 0s;
			opacity: 1;
			z-index: 2;
		}


		&.is-expanded{
			&:before{
				opacity: 0;
			}
		}
	}

	&__bottom{
		padding-left: 16px; 
		padding-right: 16px; 
	}

	&__button{
		background-color: #A1A8AF;
		color: #ffffff;
		border-radius: 0;
		width: 100%;
		height: 40px;
		font-family: $ff_medium;

		&:hover{
			background-color: rgba(#A1A8AF, 0.8);
		}
	}
}

.table-body-sticky-row {
	position: sticky;
	top: calc($platform-navigation-height + $platform-header-height + 110px);
	background-color: #fff;
	z-index: 1;
}