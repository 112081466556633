@import '@theme';

.SelectionStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    $root: &;

    &__title {
        font-family: $ff_medium;
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
        cursor: pointer;
        transition: color 300ms;

        &:hover {
            color: $lightblue;
        }
    }

    &__sub-title {
        display: flex;
        color: #788092;
        font-size: 13px;
        line-height: 13px;
        font-style: italic;
    }

    &__popup {
        width: 300px;
        max-height: 300px;
        overflow-x: hidden;
        padding: 10px 8px;
        cursor: default;
    }

    &__popup-item {
        border-bottom: 1px dashed #ddd;
        padding: 4px 24px 4px 8px;
        font-size: 12px;
        line-height: 14px;
        text-transform: none;
        position: relative;

        &:last-child {
            border: 0;
        }

        &:hover {
            background-color: rgba($lightblue, 0.2);
        }
    }
    
    &__popup-icon {
        position: absolute;
        top: 50%;
        right: 5px;
        height: 14px;
        width: 14px;
        cursor: pointer;
        transform: translateY(-50%);
        display: none;

        #{$root}__popup-item:hover & {
            display: block;
        }

        &:hover {
            color: $red;
        }
    }
}