@import '@theme';

.HeaderBar {
    position: relative;
    background-color: $header_bar_bg;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}