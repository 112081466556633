@import '@theme';

.StarCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 16px;
    color: #000;

    @include media-max(1500px) {
        font-size: 14px;
    }

    .Stars {
        justify-content: center;
        width: 70px;
    }
}