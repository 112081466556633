


.card-scores-plus-content{
	display: flex; 
	color: #4A4A4A;
	margin-left: -20px; 
	margin-right: -20px; 
}


.card-scores-plus-column {
	color: #4A4A4A;
	flex: 1 0 50%;
	box-sizing: border-box;
	padding: 0 20px;
	border-right: 1px dashed #979797;

	&:last-child{
		border-right: none;
	}
}