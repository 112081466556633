@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@theme';

.KeywordSettings {
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: $lightblue;
        color: #ffffff;
    }

    &__title {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:not(:first-child) {
            margin-top: 20px;
        }

        &.green {
            color: #28B547;
        }

        &.red {
            color: #de2727;
        }

        &:after {
            content: '';
            display: block;
            height: 1px;
            background: #979797;
            flex: 0 1 100%;
            margin-left: 10px;
        }
    }

    &__popup {
        min-width: 120px;

        &.size-s {
            max-width: 200px;
        }
        &.size-m {
            max-width: 400px;
        }
        &.size-l {
            max-width: 600px;
        }
        &.size-xl {
            max-width: 800px;
        }
    }
}