@import '@theme';

.CollapsibleSection {
    border-bottom: 1px solid #ccc;
    position: relative;
    $root: &;

    &__content {
        box-sizing: border-box;
        padding-bottom: 16px;
        display: none;

        #{$root}.is-opened & {
            display: block;
        }
    }

    &__icon {
        transform-origin: center center 0;
        transition: transform .3s ease;
        height: 20px;

        #{$root}.is-opened & {
            transform: rotateX(-180deg);
        }
    }

    &.is-disabled {
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: -1px;
            bottom: -1px;
            left: -10px;
            right: -10px;
            background: rgba(#000, 0.2);
        }
    }
}

.CollapsibleSectionHeader {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    user-select: none;
    cursor: pointer;
    $root: &;
    background-color: #fff;
    z-index: 2;

    &:hover {
        #{$root}__caption {
            color: $lightblue;
        }
    }

    &__content {
        margin-left: 5px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4a4a4a;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &__column {
        display: flex;
        align-items: center;
    }

    &__caption {
        font-family: $ff_medium;
        text-transform: uppercase;
    }

    &__sub-caption {
        margin-left: 16px;
    }
}