@import '@theme';

.FoodWatchItem {
    &__column {
        width: 45%;
    }

    .card {
        margin-bottom: 20px;
        min-height: 260px;
    }

    .card-header.is-sticky {
        position: sticky;
        top: $header_height;
        z-index: 3;
    }

    .DataFallback {
        font-style: italic;
    }

    .DataFallback__sub-title {
        font-size: 14px;
    }
}