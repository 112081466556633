


.card-scores-plus-section{
	margin-top: 45px;

	&:first-child{
		margin-top: 0;
	}

	&__caption{
		font-family: 'DIN Pro Bold';
		text-transform: uppercase;
	}

	&__bottom{
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;

		.mark{
			color: #32C1E9;
			padding-right: 5px; 
		}
	}
}

.card-scores-plus-list{
	margin-top: 10px;

	&__item {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px dashed #DADADA;
		align-items: center;
		font-size: 14px;
		line-height: 18px;
		padding-top: 6px;
		padding-bottom: 6px;
	}
}

.card-scores-plus-percentile{
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;

	.star-rating{
		margin-right: 5px;
	}
}

.card-scores-plus-switch {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;

	&__button{
		font-size: 16px;
		margin-left: 5px;
		margin-right: 5px;

		&:first-child{
			margin-left: 0;
		}

		&:last-child{
			margin-right: 0;
		}

		&.is-active{
			color: #32C1E9;
		}
	}
}
