.ButtonTabs {
    display: flex;
    border-radius: 4px;

    &__item {
        border-radius: 0;
        width: 120px;
        height: 40px;
        border-left: 1px solid #ddd;

        &:first-child {
            border-radius: 4px 0 0 4px;
            border-left: 0;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }
}