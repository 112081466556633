@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;

@import '@theme';

.Benchmark {
    min-height: calc(100vh - #{$header_height + $footer_height});

    &__header-bar {
        position: sticky;
        top: calc($platform-navigation-height + $platform-header-height);
        z-index: 3;
        margin-bottom: 20px;
    }

    &__card {
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #3a414c;
        font-size: 15px;
        line-height: 17px;
    }

    &__card-text {
        font-family: $ff_light;
        margin-bottom: 10px;
    }

    &__card-title {
        text-transform: uppercase;
        color: #000;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 10px;
        
        span {
            color: #989898;
        }
    }

    &__inputs {
        background: #f5f5f5;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        width: 1050px;
        border-radius: 4px;
        margin-bottom: 15px;
    }

    &__tooltip {
        .TableTooltip__header {
            white-space: normal;
            font-size: 16px;
            line-height: 18px;
            font-family: $ff_normal;
        }
    }


    .Field__label {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 10px;
    }

    .Select {
        width: auto;
        min-width: 150px;
        height: 40px;
    }

    .Select__thumb {
        background: #fff;
    }

    .Select__dropdown {
        max-height: 300px;
        min-width: 100%;
        width: auto;
        white-space: nowrap;
    }
}

.BenchmarkMetric {
    height: 40px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #b9bfc7;
    position: relative;
    margin-top: 25px;

    &__icon {
        margin-left: 10px;
        color: #b9bfc7;
        cursor: pointer;
        width: 16px;
        height: 16px;

        &:hover {
            color: $lightblue;
        }

        & > * {
            width: 16px;
            height: 16px;
        }
    }

    &__label {
        font-size: 14px;
        font-family: $ff_bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        color: #4a4a4a;
    }

    &__input {
        padding: 10px 38px 10px 0;
        width: 100%;
        height: 100%;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        background-color: transparent;
        border: 0;
        color: #4a4a4a;
    }

    &:after {
        content: '%';
        display: block;
        width: 38px;
        height: 100%;
        line-height: 38px;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 4px 4px 0;
        background: #f0f0f0;
    }
}