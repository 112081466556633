
.__card-benchmark{
	display: flex;
	flex-direction: column;
}

.card-benchmark-header{
	display: flex;
	align-items: center;
	justify-content: space-between;


	&__description {
		text-align: right;
		line-height: 16px;
		font-size: 14px;
		font-family: "DIN Pro Regular";
	}
}

.card-benchmark-content {
	padding: 16px 24px;
	display: flex;
	flex-grow: 1;
	align-items: flex-end;
	
	&--full-width{
		.card-benchmark-chart{
			width: 100%;
		}
	}

	&__column{
		flex: 0 0 33.33%;
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding-left: 9px;
		padding-right: 9px;
	}


}

.card-benchmark-chart {
	font-family: 'DIN Pro Medium';
	color: #4A4A4A;
	position: relative;
	width: 230px;

	.recharts-cartesian-axis-ticks{
		.recharts-cartesian-axis-tick{
			opacity: 0;

			&:first-child,
			&:last-child{
				opacity: 1;
			}
		}

	}

	&__bottom{
		text-align: center;
		background-color: rgba(49, 53, 74, 0.05);
		color: #141831;
		padding-top: 5px;
		padding-bottom: 5px;
		line-height: 14px;

		.caption {
			font-family: 'DIN Pro Light';
		}

		.name{
			font-family: 'DIN Pro Bold';
		}

		.value{
			font-size: 10px;
		}
	}
}

.card-benchmark-chart-point{
	position: absolute;
	width: 22px;
	height: 22px;
	background-color: white;
	z-index: 1;
	transform: translate(-50%, -50%);
	box-shadow: 4.57267px 4.57267px 16.0043px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	border: 3.4295px solid #FE944D;
	box-sizing: border-box;

	transition: all 0.3s ease 0s;

	opacity: 0;

	&.is-active{
		opacity: 1;
	}

	&:before{
		content: "";
		display: block;
		width: 1px;
		height: 15px;
		position: absolute;
		top: -3px;
		transform: translateY(-100%) translateX(-50%);
		background: #2d343e;
		left: 50%;
	}

	&__tooltip {
		font-family: 'DIN Pro Regular';
		position: absolute;
		background: #2D343E;
		border-radius: 62px;
		color: #ffffff;
		font-size: 11px;
		line-height: 12px;
		width: 66px;
		text-align: center;
		padding-bottom: 3px;
		padding-top: 2px;
		left: 50%;
		transform: translateX(-50%);
		bottom: 28px;

		.value{
			font-family: 'DIN Pro Medium';
		}
	}
}