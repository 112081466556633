


.card-highlighting {
	display: flex;
	flex-direction: column;
}


.card-highlighting-header{
	text-transform: uppercase;
}

.card-highlighting-name{
	color: #8dca0f;

	&--dislike{
		color: #f97960;
	}
}


.card-highlighting-content {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.card-highlighting-container{
	display: flex; 
	color: #4A4A4A;
}


.card-highlighting-words{
	box-sizing: border-box;
	padding-right: 18px;
	border-right: 1px dashed #979797;
	flex-basis: 45%;
  max-width: 45%;

	&--wide{
		flex-basis: auto;
		flex-grow: 1;
		padding-right: 0;
		border-right: none;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px dashed #DADADA;
		align-items: center;
		font-size: 16px;
		line-height: 19px;
		padding-top: 5px; 
		padding-bottom: 5px; 
	}
}

.card-highlighting-answers{
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 24px;
	flex-basis: 55%;
  max-width: 55%;
  overflow: hidden;

	&--wide{
		flex-basis: auto;
		flex-grow: 1;
		padding-left: 25px;
	}

	&__item{
		border-bottom: 1px solid #E1E1E1;
		position: relative;
		padding-bottom: 15px;
		margin-top: 15px;
		line-height: 17px;

		&:first-child{
			margin-top: 0;
		}

		&:before {
			content: open-quote;
			font-size: 36px;
			line-height: 14px;
			position: absolute;
			text-align: center;
			color: #959595;
			left: -25px;
			top: 15px;
		}

		&:after {
			content: close-quote;
			font-size: 36px;
			line-height: 14px;
			position: absolute;
			text-align: center;
			color: #959595;
			right: -24px;
			bottom: 0px;
		}
	}
}

.card-highlighting-bottom {
	display: flex;
	margin-top: 20px;
	font-size: 15px;
	color: #AFAFAF;
	justify-content: space-between;
	align-items: flex-end;
	flex-grow: 1;

	font-family: 'DIN Pro Light';

	&__button{
		color: #2087D2;
		text-decoration: underline; 
		font-family: 'DIN Pro Regular';

		&:hover{
			text-decoration: none;
		}
	}
}

.card-highlighting-modal{
	.modal-window {
		max-width: 805px;
	}	
}