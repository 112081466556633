@import '@theme';

.DataFallback {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-block: 60px;
    padding-inline: 30px;

    &__title {
        font-size: 32px;
        color: $medblue;
    }
    
    &__sub-title {
        font-size: 18px;
        line-height: 19px;
        color: #272727;
        max-width: 500px;
    }
}