.ChartView {
    position: relative;
    display: flex;

    &__chart {
        width: calc(100% - 320px);
        position: sticky;
        top: 140px;
    }

    &__sidebar {
        width: 280px;
        margin-left: 40px;
    }
}

.ChartViewSidebar {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    $header_height: 100px;
    $footer_height: 60px;

    &__header {
        position: sticky;
        top: 140px;
        padding: 30px 16px;
        background: #f2f4f8;
        height: $header_height;
        z-index: 2;
    }

    &__content {
        position: relative;
        padding: 24px 16px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - #{$header_height + $footer_height});
    }

    &__row {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        justify-content: center;

        &.is-disabled {
            pointer-events: none;
        }
    }

    &__footer {
        position: sticky;
        bottom: 60px;
        height: $footer_height;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        background: #f2f4f8;
    }

    &__color-column {
        width: 16px;
        margin-right: 8px;
    }

    &__select-column {
        width: calc(100% - 48px);
    }

    &__delete-column {
        width: 16px;
        margin-left: 8px;
    }

    &__delete-icon {
        cursor: pointer;
        transition: color 300ms;

        &:hover {
            color: red;
        }
    }

    &__add-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
    }
}