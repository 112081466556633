@import '@theme';

@font-face {
    font-family: $font_normal;
    font-style: normal;

    src: local(''),
    url('./fonts/DINPro.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: $font_light;
    font-style: normal;
    src: local(''),
    url('./fonts/DINPro-Light.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: $font_medium;
    font-style: normal;
    src: local(''),
    url('./fonts/DINPro-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: $font_bold;
    font-style: normal;
    src: local(''),
    url('./fonts/DINPro-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: $font_caption;
    src: local(''),
    url('./fonts/6f99c824ca1981d3cf4b7bb6cfc2507b.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: $font_icons;
    src: local(''),
    url('./fonts/icomoon.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.body-bg {
    background: $body_bg;
}

body {
    min-width: $wrapper_min_width;
    background: $body_bg;
    font-size: 14px;
    line-height: 1.6;
    font-family: 'DIN Pro Regular', sans-serif;
    font-weight: 400;
    color: #141932;
}

button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    background: transparent;
    list-style: none;
    -webkit-appearance: none;
    font-size: 15px;
}

.color-dimyellow {
    color: $dimyellow;
}

.color-darkyellow {
    color: $darkyellow;
}

.color-lightblue {
    color: $lightblue;
}

.color-red {
    color: $red;
}

.border-color-red {
    border-color: $red;
}

.is-error-cl {
    color: $error;
}

.bg-green {
    background-color: $green;
}

.text-center {
    text-align: center;
}

.font-normal {
    font-family: $ff_normal;
}

.font-light {
    font-family: $ff_light;
}

.font-medium {
    font-family: $ff_medium;
}

.font-bold {
    font-family: $ff_bold;
}

.font-caption {
    font-family: $ff_caption;
}

.font-icons {
    font-family: $ff_icons;
}

.section {
    margin: 20px 0;
}

.window-scroller-override {
    height: 100% !important;
    overflow: visible !important;
}

.react-tooltip.react-tooltip__show {
    opacity: 1;
    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.2));
}

@keyframes fade-in-delay {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.lines-3 {
    overflow: hidden;
    padding-block: 2px;
    @include multilines-ellipsis(3);
}

.link {
    color: $blue;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.card {
    background: linear-gradient(0deg,#fff,#fff);
    box-shadow: 1px 3px 12px rgba(0,0,0,.1);
    border-radius: 3px;
}

.card-header {
    background: $deepblue;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: 20px 24px;
    font-family: $ff_medium;
    font-size: 20px;
    line-height: 20px;
}

.card-content {
    box-sizing: border-box;
    padding: 24px;
}

.locked-scroll {
    overflow: hidden !important;
}

.color-yellow {
    color: $yellow;
}

b {
    font-family: $ff_bold;
}

.center-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.action-icon {
    transition: transform 300ms, opacity 300ms;
    cursor: pointer;
    opacity: 0.6;

    &:hover {
        transform: scale(1.1);
        opacity: 1;
    }
}

@keyframes action-button-icon {
    0% {
        color: #333943;
    }
    50% {
        color: $lightblue;
    }
    100% {
        color: #333943;
    }
}

.ActionButton {
    color: #333943;
    text-transform: uppercase;
    margin: 0 4px;
    padding: 6px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    $root: &;
    transition: color 300ms;

    &:hover {
        color: $lightblue;
    }

    &__icon {
        margin: 0 4px;
        #{$root}:hover & {
            animation: action-button-icon 1s linear infinite;
        }
    }
}

@keyframes ProgressLoaderAnimation {
    to {
        background-position: 4.5em 0;
    }
}

.ProgressLoader {
    position: relative;
    width: 330px;
    height: 10px;
    border: 1px solid #89898a;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;

    &.is-inside-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 6px;
        transform: translateX(-50%);
        opacity: 0.5;
        border: 0;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: repeating-linear-gradient(-45deg, #f3f7f7, #f3f7f7 10%, #cdd2d3 10%, #cdd2d3 17%);
        background-size: 4.5em 4.5em;
        animation: ProgressLoaderAnimation 900ms infinite linear;
        z-index: 30;
    }

    &:after {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 40;
    }
}

.Overflow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

:root {
    --toastify-color-info: #{$lightblue};
    --toastify-color-success: #{$green};
    --toastify-color-error: #{$red};
}

.Indicator {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    right: calc(100% + 4px);
    top: 50%;
    margin-top: -4px;
    background: #ddd;
}

.header-title {
    font-size: 18px;
    line-height: 18px;
    font-family: $ff_medium;
}

.header-sub-title {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    color: #c1c1c1;
}

.inline {
    display: inline;
}