@import '@theme';

.ChainPerformance {
    padding-bottom: 30px;

    &__table {
        position: relative;
        height: calc(100vh - #{$header_height + $footer_height + 170px});
        min-height: 600px;
        background: #fff;
        margin-bottom: 20px;
        animation: fade-in-delay 500ms;
    }

    &__chart {
        position: relative;
        background: #fff;
        min-height: 420px;
        display: flex;
        box-shadow: 0 4px 10px 0 rgba(#000, 0.1);
        animation: fade-in-delay 500ms;
    }
}