@import '@theme';

.Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__scroll-top {
        position: fixed;
        bottom: 100px;
        right: 50%;
        margin-right: -$wrapper_width * 0.5 - 20px;
        width: 40px;
        height: 40px;
        background: $header_bar_bg;
        color: #ffffff;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        opacity: 0;
        transform: translateY(-100%);
        pointer-events: none;
        transition: opacity 300ms, transform 300ms, background-color 300ms;

        &:hover {
            background-color: lighten($header_bar_bg, 10%);
        }

        &.is-shown {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }

        @include media-max($wrapper_width + 40px) {
            margin-right: 0;
            right: 10px;
        }
    }
}