@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@use '@datassential/platform-ui-lib/lib/style/templates' as *;

.PlatformHeader {
    z-index: 5;
    &__logo {
        margin-right: rem(10px);
    }

    &__nav-item {
        @include header-nav-item;
    }

    &__nav-icon {
        width: rem(12px);
        height: rem(12px);
        margin-top: rem(2px);
        margin-left: rem(8px);
    }

    b {
        font-family: inherit;
    }

    &__search-link {
        cursor: pointer;
    }

    &__search-info {
        position: absolute;
        right: rem(40px);
        width: rem(24px);
        height: rem(24px);
    }

    &__search-action {
        width: rem(80px);
    }
}