@import '@theme';

.TimeframesBarChart {
    $height: 420px;
    $top-indent: 80px;
    $bottom-indent: 50px;
    $left-indent: 60px;
    $right-indent: 20px;
    $axis-color: rgba(#000, 0.3);
    $root: &;
    $bar-indent: 10px;
    $month-bar-width: 10px;
    $season-bar-width: 40px;
    $year-bar-width: 110px;

    height: 100%;
    width: 100%;

    &__viewport {
        position: relative;
        width: 100%;
        height: $height;
        padding: 0 $right-indent;

        &.has-y-axis {
            padding: 0 $right-indent 0 $left-indent;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: $bottom-indent;
            left: $right-indent;
            right: $right-indent;
            border-bottom: 1px solid $axis-color;

            #{$root}.variant-month & {
                left: $left-indent;
            }
        }

        &.is-gap:after {
            bottom: $bottom-indent + 20px;
        }

        &.is-no-legend {
            height: $height - $top-indent;
        }
    }

    &__legend {
        position: absolute;
        height: $top-indent - 40px;
        width: 100%;
        top: 20px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $right-indent;
        z-index: 2;
        margin-bottom: 30px;
    }

    &__legend-message {
        font-family: $ff_medium;
    }

    &__legend-button {
        background: #f3f3f3;
        margin-right: 15px;
        width: 120px;
        height: 40px;
        padding: 0 10px;

        &:hover {
            box-shadow: none;
            background: #f9f9f9;
        }
    }

    &__legend-button-icon {
        width: 20px;
        height: 20px;
        color: #ff6a00;
        margin-right: 10px;
    }

    &__y-axis {
        position: absolute;
        left: 0;
        width: $left-indent;
        top: $top-indent;
        bottom: $bottom-indent + 0.5px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        border-right: 1px solid $axis-color;
    }

    &__y-axis-label {
        display: flex;
        height: 0;
        align-items: center;
        justify-content: flex-end;
        font-family: $ff-medium;
        font-size: 15px;

        &:after {
            content: '';
            display: block;
            height: 0;
            width: 8px;
            margin-left: 2px;
            border-top: 1px solid $axis-color;
        }
    }

    &__view {
        height: $height;
        display: flex;
        padding-top: $top-indent;
        padding-bottom: $bottom-indent;

        &.is-gap {
            padding-bottom: $bottom-indent + 20px;
        }

        &.is-no-legend {
            padding-top: 20px;
            height: $height - $top-indent;
        }
    }

    &__groups {
        display: flex;
        justify-content: center;
        flex: 0 1 100%;
    }

    &__group {
        height: 100%;
        position: relative;
        display: flex;
        padding: 0 $bar-indent * 0.5;

        &:before {
            $line-offset: 10px;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            height: calc(100% - #{$line-offset});
            width: 0;
            border-left: 1px dashed $axis-color;
        }

        &:last-child:before {
            display: none;
        }

        &.variant-month {
            padding: 0 $month-bar-width * 0.5;
        }

        &.variant-season {
            padding: 0 ($season-bar-width - $bar-indent) * 0.5;
        }

        &.variant-year {
            padding: 0 ($year-bar-width - $bar-indent) * 0.5;
        }
    }

    &__group-value {
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        text-align: center;
        color: #ff6a00;
        font-family: $ff_medium;
        font-size: 16px;
        line-height: 30px;
        height: 30px;
        white-space: nowrap;
        display: flex;
        padding-left: $bar-indent;
        padding-right: $bar-indent;
        direction: ltr;
        align-items: center;
        $border-color: rgba(#000, 0.4);
        $border-radius: 3px;

        #{$root}__group.variant-month & {
            padding-left: $bar-indent - 2px;
            padding-right: $bar-indent - 2px;
        }

        #{$root}__group.variant-season & {
            padding-left: 0.5 * (0 + $season-bar-width) - 2px;
            padding-right: 0.5 * (0 + $season-bar-width) - 2px;
        }

        #{$root}__group.variant-year & {
            padding-left: 0.5 * (0 + $year-bar-width) - 2px;
            padding-right: 0.5 * (0 + $year-bar-width) - 2px;
        }

        &:before, &:after {
            content: '';
            display: block;
            flex: 0 1 100%;
            border: 1px solid transparent;
            border-top-color: $border-color;
            transform: translateY(50%);
            height: 20px;
        }

        &:before {
            border-radius: $border-radius 0 0 0;
            border-left-color: $border-color;
            margin-right: 5px;
        }

        &:after {
            border-radius: 0 $border-radius 0 0;
            border-right-color: $border-color;
            margin-left: 5px;
        }
    }

    &__values {
        display: flex;
        height: 100%;
        align-items: flex-end;

        #{$root}__group-value + & {
            padding-top: 30px;
        }
    }

    &__group-label {
        position: absolute;
        top: calc(100% + 10px);
        font-family: $ff-medium;
        font-size: 16px;
        white-space: nowrap;
        direction: ltr;
        left: 50%;
        transform: translateX(-50%);

        #{$root}__group.is-gap & {
            width: calc(100% - 6px);
            top: calc(100% + 30px);
            background: #e1e1e1;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__bar {
        position: relative;
        width: 10px;
        margin: 0 $bar-indent * 0.5;
        border-radius: 3px 3px 0 0;
        background-size: 100% #{$height - $top-indent - $bottom-indent};
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: linear-gradient(to top, #ffcc02, #ff6a00);
        transition: opacity 500ms, filter 500ms;

        &.variant-month {
            width: $month-bar-width;
        }

        &.variant-season {
            width: $season-bar-width;
        }

        &.variant-year {
            width: $year-bar-width;
        }

        &.is-clickable {
            cursor: pointer;
        }

        &.is-active-dimmed {
            opacity: 0.5;
        }

        &.is-disabled {
            opacity: 0.2;
        }

        &.is-dimmed {
            filter: grayscale(1);
            opacity: 0.1;
            pointer-events: none;
        }

        &:hover {
            opacity: 1;
        }
    }

    &__bar-value {
        position: absolute;
        bottom: 100%;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
    }

    &__bar-label {
        position: absolute;
        top: calc(100% + 10px);
        white-space: nowrap;
        left: 50%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        font-family: $ff_medium;
        font-size: 14px;
    }

    ::-webkit-scrollbar {
        visibility: hidden;
        min-height: 1px;
        max-height: 1px;
        min-width: 1px;
        max-width: 1px;
    }
}