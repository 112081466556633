.ColorPicker {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 2px 0 rgba(#000, 0.6);

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
}