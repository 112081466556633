@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import "@theme";

.MyConcepts {
    &__header-bar {
        position: sticky;
        top: calc($platform-navigation-height + $platform-header-height);
        z-index: 3;
    }
}