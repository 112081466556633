@import '@theme';

.TimeframesChart {
    $height: 380px;
    $top-indent: 60px;
    $bottom-indent: 65px;
    $left-indent: 60px;
    $right-indent: 20px;
    $axis-color: rgba(#000, 0.3);
    $root: &;
    $bar-indent: 10px;
    $month-bar-width: 20px;
    $season-bar-width: 60px;
    $year-bar-width: 200px;
    width: 100%;

    &__viewport {
        position: relative;
        width: 100%;
        height: $height;
        padding: 0 $right-indent;
        $viewport: &;

        &.has-y-axis {
            padding: 0 $right-indent 0 $left-indent;
            &:after {
                left: $left-indent;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: $bottom-indent;
            left: $right-indent;
            right: $right-indent;
            border-bottom: 1px solid $axis-color;
        }
    }

    &__legend {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 30px $right-indent;
        z-index: 2;
    }

    &__legend-button {
        position: relative;
        background: #f3f3f3;
        margin-right: 15px;
        margin-bottom: 15px;
        height: 40px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        white-space: nowrap;
        transition: padding 300ms;

        &:hover {
            box-shadow: none;
            background: #e5e5e5;
            padding: 0 26px 0 8px;
        }
    }

    &__legend-button-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        transform: translateY(-50%);
        cursor: pointer;
        color: lighten($header_bg, 20%);
        box-shadow: 0 0 0 1px lighten($header_bg, 20%);
        z-index: 2;
        pointer-events: none;
        opacity: 0;

        #{$root}__legend-button:hover & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__legend-button-color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
        transition: margin-right 300ms;
        #{$root}__legend-button:hover & {
            margin-right: 8px;
        }
    }

    &__y-axis {
        position: absolute;
        left: 0;
        width: $left-indent;
        top: $top-indent;
        bottom: $bottom-indent + 0.5px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        border-right: 1px solid $axis-color;
    }

    &__y-axis-label {
        display: flex;
        height: 0;
        align-items: center;
        justify-content: flex-end;
        font-family: $ff-medium;
        font-size: 15px;

        &:after {
            content: '';
            display: block;
            height: 0;
            width: 8px;
            margin-left: 2px;
            border-top: 1px solid $axis-color;
        }
    }

    &__view {
        height: $height;
        display: flex;
        padding-top: $top-indent;
        padding-bottom: $bottom-indent;
    }

    &__groups {
        display: flex;
        position: relative;
        justify-content: center;
        flex: 0 1 100%;
    }

    &__group {
        height: 100%;
        position: relative;
        display: flex;

        &:before {
            $line-offset: 10px;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            //height: calc(100% - #{$line-offset});
            height: 100%;
            width: 0;
            border-left: 1px dashed $axis-color;
        }

        &:last-child:before {
            display: none;
        }
    }

    &__values {
        display: flex;
        height: 100%;
        align-items: flex-end;
    }

    &__group-label {
        position: absolute;
        top: calc(100% + 10px);
        font-family: $ff-medium;
        font-size: 16px;
        line-height: 25px;
        height: 25px;
        white-space: nowrap;
        direction: ltr;
        left: 50%;
        width: calc(100% - 6px);
        transform: translateX(-50%);
        border-radius: 4px;
        text-align: center;

        #{$root}.variant-season & {
            top: calc(100% + 30px);
            background: #e1e1e1;
        }
    }

    &__bar {
        position: relative;
        width: 10px;
        height: 100%;
        transition: opacity 500ms, filter 500ms;

        &:before {
            $line-offset: 10px;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 0;
            border-left: 1px dashed $axis-color;
            opacity: .5;
        }

        &.variant-month {
            width: $month-bar-width;
        }

        &.variant-season {
            width: $season-bar-width;
        }

        &.variant-year {
            width: $year-bar-width;
        }
    }

    &__bar-inner {
        width: 100%;
        height: 100%;

        &:hover {
            background-color: rgba(#000, 0.05);
        }

        &.is-empty {
            pointer-events: none;
        }
    }

    &__bar-label {
        position: absolute;
        top: calc(100% + 10px);
        white-space: nowrap;
        left: 50%;
        width: calc(100% - 2px);
        direction: ltr;
        text-align: center;
        transform: translateX(-50%);
        text-transform: uppercase;
        font-family: $ff_medium;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
    }
    
    &__point {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid;
        background: #fff;
    }

    &__curves {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    ::-webkit-scrollbar {
        visibility: hidden;
        min-height: 1px;
        max-height: 1px;
        min-width: 1px;
        max-width: 1px;
    }

    &__tooltip-title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
        font-family: $ff_medium;
        text-align: center;
    }
    &__tooltip-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        direction: ltr;
    }

    &__tooltip-row-color {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 10px;
    }
    
    &__tooltip-row-value {
        width: 30px;
        margin-left: 10px;
        text-align: left;
    }

    &__dot,
    &__curve {
        transition: opacity 300ms, fill 300ms;
    }
}