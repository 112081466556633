@import "@theme";

.Switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    $root: &;
    $control-height: 35px;
    $control-width: 80px;
    user-select: none;

    &__control {
        width: $control-width;
        height: $control-height;
        border-radius: 0.5 * $control-height;
        background-color: #dadada;
        margin: 0 8px;
    }

    &__control-thumb {
        width: $control-height;
        height: $control-height;
        background: $gray_cl;
        color: #fff;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background 300ms, transform 300ms;

        &.is-toggled-on {
            transform: translateX(#{$control-width - $control-height}) rotate(180deg);
        }

        &.is-green {
            background: $green;
        }
        &.is-blue {
            background: $lightblue;
        }
    }
}