@import '@theme';

.MultiSelectBox {
    position: relative;
    width: 100%;
    padding-top: 5px;
    $root: &;

    &__thumbnail {
        position: relative;
        width: 100%;
    }

    &__input {
        &.is-error {
            border-color: $red;
        }
    }

    &__autocomplete {
        position: absolute;
        width: 90%;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(#000, 0.3);
        z-index: 2;
    }

    &__autocomplete-item {
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }


    &__value {
        width: 100%;
        height: 150px;
        border-radius: 0 0 4px 4px;
        background: #f4f4f4;
        padding: 10px 0;
        font-family: $ff_medium;
        border: solid 1px #ccd5de;
        margin-top: -4px;
    }

    &__value-item {
        padding: 4px 10px;
        position: relative;
        padding-right: 20px;

        &:hover {
            background-color: #fff;
        }
    }

    &__value-item-remove {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 20px;
        line-height: 10px;
        opacity: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            width: 2px;
            height: 10px;
            background: #000;
            transform-origin: center;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:hover {
            background: #e0e0e0;
        }

        #{$root}__value-item:hover & {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__error-message {
        color: $red;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 10px;
        position: absolute;
        top: -11px;
        right: 0;
        font-family: $ff-medium;
    }
}