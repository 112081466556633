.Loader {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
}