@import '@theme';

.item-header {
	display: flex;
	z-index: 3;
	overflow: hidden;
}

.item-header-container{
	flex-grow: 1;
}

.item-header-enter{
	display: flex;
	margin: 0 auto;
}

.item-header-wrapper {
	width: 720px;
}


.item-header-card {
	display: flex;
	align-items: center;
	padding: 8px;
	flex-grow: 1;
	box-sizing: border-box;
	min-height: 140px;
}


.card-item-header-image{
	margin-right: 24px;

	&__figure{
		width: 236px;
		border-radius: 4px;
		overflow: hidden;
		cursor: pointer;
		margin: 0;
	}

	img {
		display: block;
		width: 100%;
		border-radius: 4px;
	}
}

.card-item-header-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;

	&__chain {
		display: flex;
		justify-content: space-between;
		font-size: 16px;

		.link{
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	&__name{
		margin-top: 5px;
		color: #000000;
		letter-spacing: -1px;
		text-transform: uppercase;

		font-family: 'DIN Pro Medium';
		font-size: 26px;
		line-height: 24px;
		display: flex;
		align-items: flex-end;
		padding-bottom: 8px;
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__description,
	&__summary{
		font-family: 'DIN Pro Light';
		font-size: 19px;
		line-height: 21px;
		color: #141932;
	}

	&__status {
		padding: 8px 12px;
		border-radius: 4px;
		background: #f2f2f2;
		width: 100%;
		color: $deepblue;
		margin-top: 8px;
		font-size: 14px;

		.item-header-wrapper {
			display: flex;
			align-items: flex-start;
		}
	}

	&__status-title {
		margin-right: 40px;
		display: flex;
		align-items: center;
		flex: 0 0 160px;
	}

	&__status-note {
		display: flex;
		align-items: flex-start;
	}

	&__summary{
		margin-top: 8px;
		padding-bottom: 8px;
		font-size: 14px;
		line-height: 18px;
		flex-grow: 1;

		&.is-separate {
			padding-top: 8px;
			border-top: 1px solid #3A414C;
		}

		.infoblock {
			padding-right: 5px;
		}
	}
}

.card-item-header-content-bottom {
	flex-grow: 1;
	display: flex;
	align-items: flex-end;
}




@keyframes animationProgress {
	0% {
		background-position: 0 0; 
	}
	100% {
		background-position: 35px 35px; 
	} 
 }

.item-header-report {
	display: flex;
	position: relative;
	margin-left: 8px;
	background-color: #8DCA0F;
	border-radius: 3px;
	width: 150px;
	flex-basis: 150px;
	flex-direction: column;
	overflow: hidden;
	flex-shrink: 0;
	cursor: pointer;

	&.is-loading{
		pointer-events: none;
		.item-header-report__preloader{
			height: 20px;
			opacity: 0.8;
		}
	}

	&.is-disabled{
		pointer-events: none;
		
		.item-header-report__curtain{
			background-color: #e0dede;		
		}
	}

	&__curtain{
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #8DCA0F;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 25px;
		line-height: 30px;
		text-align: center;
		color: #ffffff;
		transition: box-shadow 500ms;
	}

	&__icon {
		font-size: 50px;
		line-height: 50px;
		margin-top: 10px;
	}

	&__preloader {
		height: 0;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
		background-size: 35px 35px;
		animation: animationProgress 2s linear infinite;
		z-index: 2;
		opacity: 0;
		transition: height 0.3s ease 0s;
	}

	&:not(.is-loading):hover{
		.item-header-report__curtain {
			box-shadow: inset 0 0 1000px rgba(#000, 0.2);
		}
	}

	&__line{
		display: block;
		width: 80%;
		position: absolute;
		top: 50%;
		height: 1px;
		background: rgba(255, 255, 255, 0.5);
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__column{
		flex: 1 0 50%;
	}

	&__button{
		height: 100%;
		width: 100%;

	
		&:hover{
			box-shadow: none;
			background-color: #88C50B;
			box-shadow: 0 0 16px 0 rgba(0,0,0,0.1) inset;
		}
	}
}

.modal-item-image{
	.image{
		display: block;
		margin: 0 auto;
		max-height: 560px;
		width: 100%;
	}
}

.modal-item-report .modal-window {
	min-width: 500px;
}