$versus-chart-select-width: 140px;
$versus-chart-line-indent: 14px;
$versus-chart-line-width: 2px;

.VersusScatterChart {
    padding: $versus-chart-line-indent;
    background: #2E343D;
    position: sticky;
    top: 160px;

    &__inner {
        position: relative;
        box-sizing: content-box;
        height: 500px;
        padding-left: $versus-chart-line-indent * 2 + $versus-chart-line-width + 5px;
        padding-bottom: $versus-chart-line-indent * 2 + $versus-chart-line-width + 5px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: $versus-chart-select-width;
            bottom: $versus-chart-line-indent;
            left: $versus-chart-line-indent;
            z-index: 1;
            width: $versus-chart-line-width;
            background: #fff;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: $versus-chart-select-width;
            left: $versus-chart-line-indent;
            bottom: $versus-chart-line-indent;
            z-index: 1;
            height: $versus-chart-line-width;
            background: #fff;
        }
    }

    &__select {
        z-index: 2;
    }
}

.VersusScatterChartSelect {
    width: $versus-chart-line-indent * 2 + $versus-chart-line-width;
    height: $versus-chart-line-indent * 2 + $versus-chart-line-width;
    position: absolute;
    $dropdown-gap: 5px;
    $root: &;

    &.bottom-right {
        bottom: 0;
        right: 0;

        #{$root}__thumbnail {
            top: 0;
            right: 0;
        }

        #{$root}__dropdown {
            bottom: calc(100% + #{$dropdown-gap});
            right: 0;
        }
    }

    &.top-left {
        top: 0;
        left: 0;

        #{$root}__thumbnail {
            top: 0;
            left: 0;
            transform-origin: 0 0;
            transform: rotate(-90deg) translateX(-100%);
        }

        #{$root}__dropdown {
            left: calc(100% + #{$dropdown-gap});
            top: 0;
        }
    }

    &__thumbnail {
        position: absolute;
        display: flex;
        width: 140px;
        height: $versus-chart-line-indent * 2 + $versus-chart-line-width;
        border: 1px solid #fff;
        padding-left: 10px;
        padding-right: 5px;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;
    }

    &__dropdown {
        position: absolute;
        width: 140px;
        z-index: 100;
        visibility: hidden;
        opacity: 0;

        #{$root}.is-opened & {
            visibility: visible;
            opacity: 1;
            transition: all 0.1s ease 0s;
        }
    }

    &__option {
        transition-property: all;
        transition-timing-function: ease;
        background: #fff;
        color: #ffffff;
        cursor: pointer;
        display: block;
        font-size: 12px;
        padding: 1px 10px;
        text-transform: uppercase;

        #{$root}.is-opened & {
            background-color: #3A414D;
            transition-duration: 0.4s;
        }

        &:hover {
            box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.2);
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i + 1}) {
                transition-delay: $i * 20ms;
            }
        }
    }
}