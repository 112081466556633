@import '@theme';

.Stars {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &__icon {
        width: 16%;
        margin-inline: 2%;
        color: transparent;

        .count-1 & { color: $red; }

        .count-2 & { color: $brown; }

        .count-3 & { color: $purple; }

        .count-4 & { color: $lightblue; }

        .count-5 & { color: $deepgreen; }
    }
}