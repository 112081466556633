@use '@datassential/platform-ui-lib/lib/style/utils' as *;

.CountriesSelect {
    &__option {
        position: relative;

        &.disabled {
            color: rgba(#000, 0.4);
        }
    }

    &__lock {
        position: absolute;
        padding-left: rem(8px);
        top: rem(2px);
        bottom: rem(2px);
        right: rem(2px);
        left: rem(2px);
        display: flex;
        align-items: center;
    }

    &__lock-icon {
        margin-right: rem(5px);
        width: rem(12px);
        height: rem(12px);
        color: #000;
    }
}