@import '@theme';

.highlighting-modal-header {
	font-size: 31px;
	line-height: 24px;
	color: #000000;
	border-bottom: 1px solid #E1E1E1;
	padding: 7px 0 27px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: -1px;
	font-family: $ff_medium;
	margin-left: -24px;
	margin-right: -24px;
}


.highlighting-modal-content{
	display: flex;
}


.highlighting-modal-words {
	position: relative;
	color: #4A4A4A;
	box-sizing: border-box;
	padding-top: 16px;
	padding-right: 16px;
	border-right: 1px solid #E1E1E1;

	min-width: 255px;
	flex-basis: 255px;

	& ~ .highlighting-modal-answers-container{
		padding-left: 16px; 
	}

	&__list {
		box-sizing: border-box;
		padding-right: 24px;
		position: relative;
	}


	&__item {
		padding: 4px 10px 4px 10px;
		color: #4a4a4a;
		border-bottom: 1px solid #E1E1E1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		line-height: 17px;
	}

	&__text {
		display: block;
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-height: 16px;
	}

	&__freq {
		font-family: $ff_medium;
	}

}

.highlighting-modal-answers-container{
	margin-top: 16px;
}

.highlighting-modal-caption {
	background-color: #F3F3F3;
	color: #44C1E5;
	text-align: center;
	text-transform: uppercase;
	padding-top: 6px;
	padding-bottom: 6px;
	font-family: $ff_medium;
	line-height: 17px;
}


.highlighting-modal-answers {
	box-sizing: border-box;
	margin-top: 8px;	

	&__list {
		color: #6b6b6b;
		font-size: 12px;
		margin-top: 0;
		box-sizing: border-box;
		height: 285px;
    	overflow-y: scroll;
    	position: relative;
		width: 430px;
	}

	&__item {
		display: flex;
		align-items: center;
		color: #4A4A4A;
		font-family: $ff_light;
		font-size: 14px;
		line-height: 20px;
		position: relative;
		padding: 8px 30px 8px 8px;
		width: calc(100% - 10px);
		overflow: hidden;

		&:hover {
			background-color: #F2F4F8;


			.highlighting-modal-answers__button{
				opacity: 1;
				pointer-events: all;
				visibility: visible;
			}
		}
	}

	&__button{
		fill: #9B9B9B;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
		pointer-events: none;
		visibility: hidden;

		transition:all 0.3s ease 0s;

		&:hover{
			fill: #E41708;
		}
	}

	&__spiner{
		fill: none;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		position: absolute;
	}
}

.highlighting-modal-bottom{
	display: flex;
	justify-content: center;
	margin-top: 30px;

	&__close,
	&__link{
		margin-left: 5px;
		margin-right: 5px;
		font-family: $ff_bold;
	}

	&__close{
		color: #27A7CA;
	}

	&__link{

		color: #ffffff;
		background: #8DCA0F;
		border-radius: 3px;
		width: 180px;
		font-family: $ff_bold;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon{
			margin-right: 5px;
		}

		&:hover,
		&.is-active {
			box-shadow: 0 110px 0 0 rgba(0, 0, 0, 0.1) inset;
		}
	}
}