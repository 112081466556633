@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import "@theme";

.CategoryActivity {
    min-height: calc(100vh - 140px);
    padding-bottom: 60px;

    .card {
        margin-bottom: 20px;
    }

    .card-header {
        padding: 15px;
        min-height: 50px;
        font-size: 15px;
    }

    &__header-bar {
        margin-bottom: 20px;
        height: 70px;

        &.is-sticky {
            position: sticky;
            top: calc($platform-navigation-height + $platform-header-height);
            z-index: 4;
        }
    }
}