@import '@theme';

.Table {
    background: #fff;
    box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px 16px;
    min-height: 200px;
    position: relative;
}

.TableHead {
    position: sticky;
    top: 0;
    height: 45px;
    display: flex;
    background-color: #fff;
    border-bottom: 2px solid #4a4a4a;
    z-index: 2;
    font-family: $ff_medium;
    color: #141932;
    padding-top: 5px;

    .Select {
        font-size: 14px;
        line-height: 14px;

        @include media-max(1500px) {
            font-size: 12px;
        }
    }
}

.TableRow {
    display: flex;
    height: 48px;
    border-bottom: 1px solid #E0E0E0;
    color: #4A4A4A;

    &:hover {
        z-index: 2;

        .TableCell {
            box-shadow: inset 0 0 0 1000px rgba(#00ceff, 0.12) !important;
        }

        &:after {
            box-shadow: inset 0 0 0 1000px rgba(#00ceff, 0.12) !important;
        }
    }
    &:after {
        content: '';
        flex: 1 0 0;
        min-height: 100%;
    }
}

.TableCell {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    padding-inline: 5px;
    height: 100%;
    flex-basis: 100%;
    position: relative;

    @include media-max(1500px) {
        font-size: 12px;
    }

    &.is-centered {
        justify-content: center;
    }

    a {
        color: $blue;
        text-decoration: none;
    }
}

.TableSortIcon {
    opacity: 0;
    display: flex;
    width: 0;
    justify-content: flex-end;
    position: relative;
    transition: opacity 300ms;

    $root: &;

    &.is-active {
        opacity: 1;
    }

    &__icon {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 2px;
        top: 0;
        transform: translateY(-50%) rotateY(180deg);
        transition: transform 300ms;

        #{$root}.is-desc & {
            transform: translateY(-50%) rotateY(180deg) rotateX(180deg);
        }
    }
}

.TableHeadCellSorting {
    cursor: pointer;

    &:hover {
        .TableSortIcon:not(.is-active) {
            opacity: 0.3;
        }
    }
}

.TableRowLoading {
    pointer-events: none;
}

@keyframes table-loader {
    0% {
        background-position-x: -150px;
    }
    100% {
        background-position-x: 500px;
    }
}

.TableCellLoading {
    position: relative;
    &:after {
        content: '';
        display: block;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 8px;
        left: 8px;
        transform: translateY(-50%);
        animation-name: table-loader;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-image: linear-gradient(
                to right,
                //red 10%,
                //blue 20%,
                //green 30%
                #e5e3e3 8%,
                #eae8e8 18%,
                #e5e3e3 33%
        );
        background-size: 500px 100%;
        background-position: 0 0;
    }
}

.TableRowCheck {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: none;
    align-items: center;
    transition: all 300ms ease 0s;
    color: transparent;
    border: 2px solid #d2d2d2;
    margin-right: 6px;
    cursor: pointer;

    &.is-permanently-shown {
        display: block;
    }

    &.is-checked {
        display: block;
        color: #ffffff;
        background-color: $green;
        border: 2px solid $green;
    }

    .TableRow:hover & {
        display: block;
    }
}