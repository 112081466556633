@import '@theme';

.TileLoader {
    position: relative;

    .Tile {
        animation-name: tile-loader;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-image: linear-gradient(
                70deg,
                #eaeaea 40%,
                #efefef 50%,
                #eaeaea 60%
        );
        background-size: 2 * $tile-height 2 * $tile-height;
        background-position: 0 0;

        * {
            display: none;
        }
    }
}

@keyframes tile-loader {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 2 * $tile-height;
    }
}