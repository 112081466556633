@import '@theme';

.PlatesSelect {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    margin-top: -4px;
    user-select: none;
    $root: &;

    &__option {
        font-size: 14px;
        line-height: 13px;
        height: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        flex: 0 0 120px;
        color: $gray_cl;
        background-color: $gray_bg;
        border: 1px solid $gray_bd;
        margin: 4px;
        border-radius: 4px;
        transition: all 300ms;
        padding-inline: 5px;

        &:hover {
            box-shadow: inset 0 0 0 200px rgba(#000, 0.05);
        }

        &.is-generic-width {
            flex: 0 0 auto;
            padding-inline: 10px;
        }

        &.is-tag-decoration  {
            flex-direction: row;
        }

        &.is-active {
            border-color: $lightblue;
            background: $lightblue;
            color: #fff;
        }

        &.is-disabled, #{$root}.is-disabled & {
            opacity: 0.7;
            pointer-events: none;
            background: #e8e8e8;
            border-color: #e8e8e8;
        }
    }

    &__option-sub-title {
        font-size: 11px;
    }

    &__option-icon {
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
        margin-left: 5px;
        margin-right: -3px;
    }
}