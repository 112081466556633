@import '@theme';

.TableTooltip {
    z-index: 5;
    width: 280px;

    &.react-tooltip__place-bottom {
        .react-tooltip-arrow {
            background-color: #343B45;
        }
    }

    &__header {
        white-space: nowrap;
        font-family: $ff_medium;
        color: #ffffff;
        display: block;
        padding: 10px;
        text-align: left;
        background-color: #343B45;
    }

    &__content {
        text-align: center;
        padding: 10px;
        font-size: 14px;
        line-height: 16px;
    }
}