@import '@theme';

.ScatterChartComponent {
    background: $header_bar_bg;

    &__dot {
        cursor: pointer;

        &:hover {
            r: 5;
            box-shadow: 0 0 0 2px rgba(#fff, 0.1);
        }
    }
}