@import '@theme';

.SelectDateRange {
    width: 100%;
    $root: &;
    padding-inline: 5px;
    color: #4a4a4a;
    font-size: 14px;

    &__header {
        display: flex;
        text-transform: uppercase;

        #{$root}__col {
            background: rgba(#000, 0.08);
            align-items: center;
            justify-content: center;
            height: 32px;
            position: relative;
        }

    }

    &__label {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    &__col {
        display: flex;
        width: 50%;
        margin-inline: 5px;
        flex-wrap: wrap;
    }

    &__content {
        margin-block: 5px;
        display: flex;
        user-select: none;
    }

    &__month {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 33.3%;
        cursor: pointer;

        &.is-disabled {
            pointer-events: none;
            opacity: 0.2;
        }

        &.is-highlight {
            background: rgba($lightblue, 0.18);
        }
        &:hover {
            background: rgba($lightblue, 0.6);
        }

        &.is-active{
            background: $lightblue;
            color: #ffffff;
            cursor: default;
        }


        &.is-error {
            background: $error;
            color: #fff;
            cursor: default;
        }
    }

    &__arrow {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 20px;

        &.is-disabled {
            pointer-events: none;
            opacity: 0.2;
            cursor: pointer;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        user-select: none;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #ccc;
        padding-top: 8px;
        margin: 5px 5px 0;
    }

    &__footer-button {
        width: 44px;
        height: 24px;
        margin-left: 10px;
        border-radius: 4px;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;

        &:hover {
            box-shadow: inset 0 0 40px rgba(#000, 0.1);
        }

        & > * {
            width: 20px;
            height: 20px;
        }
        
        &.is-disabled {
            background: #c7c7c7;
            color: #5d5d5d;
            pointer-events: none;
        }
    }
}