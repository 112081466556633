@import '@theme';

.ConceptLockerSummaryPopup {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    background: rgba(#000, 0.5);
    $root: &;

    .Wrapper {
        min-width: $wrapper_min_width;
        padding-block: 40px;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    &__inner {
        position: relative;
        width: 100%;
        display: flex;
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        animation-name: show-cl-modal-inner;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
    }

    &__overview {
        flex: 0 0 420px;
        margin-left: 40px;
    }

    &__table {
        flex: 0 1 100%;
        overflow-y: scroll;
    }

    &__title {
        font-size: 48px;
        font-family: $ff_bold;
        display: flex;
        justify-content: center;
        color: #4a4a4a;
        height: 80px;
        align-items: center;
    }

    &__pills {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 20px;
    }
    &__pill {
        height: 70px;
        min-width: 70px;
        margin-inline: 5px;
        border-radius: 10px;
        padding-inline: 10px;
        background: #e0e0e0;
        color: #4f4f4f;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-title {
            font-size: 24px;
            line-height: 35px;
            font-family: $ff_bold;
        }

        &.accent {
            background: $lightblue;
            color: #ffffff;
            height: 85px;
            padding-inline: 20px;

            #{$root}__pill-title {
                font-size: 34px;
                line-height: 40px;
            }
        }
    }
    
    &__description {
        margin-top: 30px;
        text-align: center;
        line-height: 16px;
        color: #1f1f1f;
    }

    &__metrics {
        height: 350px;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        margin-top: 30px;
        align-items: flex-start;
        justify-content: space-around;
    }

    &__metric {
        display: flex;
        align-items: center;

        &-value {
            font-size: 48px;
            font-family: $ff_bold;
            margin-right: 6px;
        }

        &-description {
            display: flex;
            flex-direction: column;
        }
    }

    &__button {
        width: 100%;
        height: 40px;
        border-radius: 20px;
        margin-top: 50px;
    }

    &__table-header {
        background: #4a4a4a;
        color: #fff;
        height: 32px;
        border-radius: 4px 4px 0 0;
    }

    &__table-sub-header {
        background: $green;
        color: #fff;
        pointer-events: none;
        font-family: $ff_medium;
    }

    &__table-row {
        height: 32px;
    }
}

@keyframes show-cl-modal-inner {
    from {
        transform: translateY(-20%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}