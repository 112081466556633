@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@theme';

.ConceptLocker {
    .TableHead {
        top: calc(70px + $platform-navigation-height + $platform-header-height);
    }

    &__header-bar {
        position: sticky;
        top: calc($platform-navigation-height + $platform-header-height);
        z-index: 3;
        margin-bottom: 20px;
        height: 70px;
    }
}