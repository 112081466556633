.SelectKeywordCategories {
    &__dropdown {
        width: 450px;
    }

    &__options {
        display: flex;
    }

    &__columns {
        columns: 2;
        color: #2d4658;

        .Field__label {
            font-size: 14px;
            margin-left: 10px;
        }
    }
}