
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	overflow: hidden;
	outline: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	transition: all 0.6s ease 0.1s;
	background: rgba(77, 77, 77, 0.4);

	z-index: 900;
}

@keyframes show-modal-window {
	from {
		transform:translate(-50%, -80%);
		opacity: 0;
	}

	to {
		transform: translate(-50%, -50%);
		opacity: 1;
	} 
}

.modal-window {
	pointer-events: auto;
	background-clip: padding-box;
	outline: 0;
	box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	animation-name: show-modal-window;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
	opacity: 1;
	background-color: #ffffff;
	box-sizing: border-box;
	left: 50%;
	padding: 24px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}


.modal-window__close {
	position: absolute;
	top: 3px;
	right: 3px;
	font-size: 24px;
	cursor: pointer;
	line-height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	background-color: #fff;
}

.modal-body {
	flex: 1 1 auto;
}

