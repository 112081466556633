@import "@theme";

.Checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    $root: &;

    &:hover {
        background: #f0f0f0;
        box-shadow: 0 0 0 4px #f0f0f0;

        #{$root}__icon:after {
            opacity: 0.2;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__icon {
        $size: 20px;
        $border_size: 2px;
        position: relative;
        display: block;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: $border_size solid $lightblue;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            flex: 0 0 $size - 4 * $border_size;
            width: $size - 4 * $border_size;
            height: $size - 4 * $border_size;
            border-radius: 50%;
            background: $lightblue;
            opacity: 0;
            transition: opacity 300ms;

            #{$root}.is-checked & {
                opacity: 1;
            }
        }
    }

    &__label {
        margin-left: 10px;
        font-size: 16px;
    }
}