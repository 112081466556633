@import '@theme';

.SelectCustom {
    width: auto;
    $root: &;

    &__thumb {
        position: relative;
        width: auto;
        white-space: nowrap;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #475161;
        color: #ffffff;
        font-size: 14px;

        #{$root}.is-opened & {
            border-color: #475161;
            border-top-color: transparent;
        }

        #{$root}.is-with-label & {
            border-top-color: transparent;
        }
    }

    &__input {
        background-color: transparent;
        border: 0;
        padding-left: 5px;
        color: #1a2024;
    }

    &__icon {
        &, * {
            fill: #1a2024;
        }
    }

    &__label {
        position: absolute;
        display: flex;
        top: 0;
        left: -1px;
        width: calc(100% + 2px);
        margin-top: -8px;
        font-size: 12px;
        line-height: 14px;
        color: $gray_bd;

        &.is-empty {
            &:before, &:after {
                margin: 0;
            }
        }

        &:before, &:after {
            content: '';
            display: block;
            height: 8px;
            position: relative;
            top: 7px;
            border: 1px solid transparent;
            border-top-color: #475161;
            pointer-events: none;
        }

        &:before {
            width: 10px;
            border-top-left-radius: 4px;
            margin-right: 3px;
        }

        &:after {
            border-top-right-radius: 4px;
            flex: 0 1 100%;
            margin-left: 3px;
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: flex-start;
    }
}