@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@import '@theme';

.Home {
    padding-bottom: 60px;
    .TableHead {
        top: $header_height + 70px;
    }

    &__bar-header {
        position: sticky;
        top: calc($platform-navigation-height + $platform-header-height);
        z-index: 3;
        margin-bottom: 20px;
        height: 70px;
    }

    &__header-section {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__bar-chart {
        height: 510px;
        transition: opacity 700ms, height 700ms;

        &.is-collapsed {
            opacity: 0;
            height: 0;
            pointer-events: none;
        }
    }

    &__bar-chart-toggle {
        height: 40px;
        display: flex;
        align-items: center;

        &-button {
            flex: 0 0;
            white-space: nowrap;
            margin-left: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-family: $ff_medium;

            &:hover {
                text-decoration: underline;
            }
        }

        &:before {
            content: '';
            display: block;
            height: 2px;
            flex: 0 1 100%;
            animation: line-gradient 2.5s linear infinite;
            background-image: linear-gradient(45deg, #ffcc02, #ff6a00, #ffcc02);
            background-size: 1700px 100%;
        }
    }

    &__bar-chart-section {
        position: relative;
        background: #fff;
        height: 420px;
        display: flex;
        box-shadow: 0 4px 10px 0 rgba(#000, 0.1);

        .TimeframesBarChart {
            flex: 0 1 100%;
        }
    }

    &__bar-chart-widget {
        height: 100%;
        flex: 0 0 220px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 10px;
    }
}

.HomeWidgetTable {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0 4px 10px rgba(#000, 0.1));

    &__header {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $ff_medium;
        background: $header_bar_bg;
        border-radius: 4px 4px 0 0;
        color: #ffffff;
        transition: background-color 300ms;

        &:hover {
            background-color: lighten($header_bar_bg, 10%);
        }
    }

    &__content {
        border: 1px solid #ddd;
        flex: 0 1 100%;
    }
}

@keyframes line-gradient {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 1700px;
    }
}