
.card-observations{
	display: flex;
}

.card-observations-aside {
	background-color: #141932;
	color: #ffffff;
	display: flex;
	align-items: center;
	flex-basis: 270px;
	justify-content: center;

	&__caption{
		font-family: 'DIN Pro Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 22px;
		text-transform: uppercase;
	}
}

.card-observations-content {
	box-sizing:border-box;
	margin-left: 24px;
	margin-right: 24px;
	padding-top: 10px;
	padding-bottom: 10px;
	flex: 1;
}

.card-observations-item {
	margin-top: 8px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	font-size: 14px;

	&__icon{
		fill: #1A2024;
		margin-right: 8px;
		width: 20px;
		height: 20px;
	}
}