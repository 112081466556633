


.card-composite-score-header{
	display: flex;
	align-items: center;
	color: #FDBF2D;
	justify-content: space-between;

	&.card-header {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.description{
		color: #F2F4F8;
		line-height: 14px;
		font-size: 14px;
		font-family: 'DIN Pro Regular';
		text-transform: none;
		font-weight: normal;
		margin-top: 2px;
	}

	.caption{
		color: #F2F4F8;
		font-family: 'DIN Pro Regular';
		font-size: 14px;
		margin-bottom: 4px;
		line-height: 16px;
		white-space: nowrap;
		font-weight: normal;
		text-transform: uppercase;
	}

	.name,
	.score{
		color: #FDBF2D;
		font-weight: bold;
		font-size: 24px;
		line-height: 20px;
		text-transform: uppercase;	
		font-family: 'Eras Bold ITC';
	}


	&__score{
		display: flex;
		align-items: center;
		flex-direction: column;

		.score{
			text-align: center;
			font-size: 40px;
			line-height: 32px;
		} 
	}
}

.card-composite-score-content{
	&--loading{
		box-sizing: border-box;
		padding: 18px
	}
}

.card-composite-score-group{

	.card-composite-score-row{
		border-bottom: 1px dashed #cccccc;
		cursor: pointer;


		&--empty{
			pointer-events: none;
			color: #CCCCCC;
		}

		&--disabled{
			cursor: default;
			.card-composite-score-button-metric{
				display: none;
			}

		}


		&:last-child{
			border-bottom: none;
		}

		&:hover{
			background-color: #f2f4f8;

			.card-composite-score-button-metric{
				opacity: 1;
				transform: scale(1) translateY(-50%);
			}
		}
	}
}

.card-composite-score-row{
	display: flex;
	color: #141932;

 
	&--header{
		color: #f2f4f8;
		line-height: 16px;
		background: #2e343d;

		.card-composite-score-column{
			padding-top: 5px;
			padding-bottom: 5px;
		}

		.rating-value{
			background-color: transparent;
		}

		.card-composite-score-column--rating{
			text-align: center;
		}
	}


	&--group{
		text-align: left;
		background-color: #f2f2f2;
		text-transform: uppercase;
		color: #000;
		border-top: none;
		font-family: "DIN Pro Medium";
		padding-left: 24px;
		padding-top: 6px;
		padding-bottom: 6px;
	}

}


.card-composite-score-column {
	padding: 12px 5px;
	box-sizing: border-box;

	font-size: 14px;
	line-height: 18px;

	.text-caption{
		color: #cccccc;
		// text-transform: lowercase;
		font-size: 13px;

	}
	.metric-caption{
		color: #7a7a7a;
		font-size: inherit;
		padding-left: 5px
	}

	&__name{
		display: flex;
		align-items: center;
		justify-content: center;

		.benchmark-direct-link {
			display: block;
			height: 18px;
			margin-left: 6px;
		}
	}

	&--main{
		flex: 1 0 auto;
		display: flex;
		position: relative;
		box-sizing: border-box;
		padding-left: 40px;
	}

	&--rating{
		flex-basis: 220px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.rating-value {
	width: 120px;
	justify-content: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: absolute;
	right: 0;
	background: #f2f4f8;
	height: 100%;
	top: 0;
}

.rating {
	display: flex;
	align-items: center;
	justify-content: center;


	&__stars{
		flex: 1 0 50px;

		.Stars {
			justify-content: flex-end;

			&__icon {
				transform: scale(1.3);
			}
		}

	}

	&__label{
		padding-left: 8px;
		flex: 0 0 110px;
		text-align: left;
		box-sizing: content-box;
	}
}


.tooltip-composite-rating-content {
	color: #1A2024;

	&__header,
	&__footer{
		color: #B9BFC7;
		padding-left: 16px;
		padding-right: 16px;
		margin-left: -16px;
		margin-right: -16px;
	}

	&__header{
		font-family: 'DIN Pro Regular';
		border-bottom: 1px solid #EFEFEF;
		padding-bottom: 10px;
		color: #141932;
	}

	&__footer{
		padding-top: 10px;
		font-family: 'DIN Pro Medium';
		border-top: 1px solid #EFEFEF;
		margin-top: 5px;
		text-transform: uppercase;
	}

	&__list{
		margin-top: 5px;
	}

	.score-item{
		font-size: 15px;
		line-height: 18px;
		font-family: 'DIN Pro Light';
		padding-top: 2px;
		padding-bottom: 2px;

		&--bold{
			font-family: 'DIN Pro Medium';
		}
	}
}


.tooltip-composite-rating-content {
	color: #1A2024;

	&__bottom{
		border-top: 1px solid #EFEFEF;
		color: #B9BFC7;
		font-family: 'DIN Pro Medium';
		padding-top: 10px;
		padding-left: 16px;
		padding-right: 16px;
		margin-left: -16px;
		margin-right: -16px;
		margin-top: 5px;

		text-transform: uppercase;

	}

	.score-item{
		font-size: 15px;
		line-height: 18px;
		font-family: 'DIN Pro Light';
		padding-top: 2px;
		padding-bottom: 2px;

		&--bold{
			font-family: 'DIN Pro Medium';
		}
	}
}


.tooltip-composite-score-content {
	color: #1A2024;
	max-width: 240px;
}

.tooltip-composite-viability-content{
	max-width: 350px;
 
	&__header{
		border-bottom: 1px solid #EFEFEF;
		font-family: 'DIN Pro Medium';
		padding: 12px 16px;

		margin-top: -16px;
		margin-left: -16px;
		margin-right: -16px;

		text-transform: uppercase;
	}

	&__item{
		padding: 6px 16px;

		margin-left: -16px;
		margin-right: -16px;

		.name{
			font-family: 'DIN Pro Medium';
			text-transform: uppercase;
		}

		&.is-selected{
			background-color: #F2F4F8;
			box-sizing: border-box;
		}
	}
}


.card-composite-score-button-metric {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: scale(0.4) translateY(-50%);
	transition: all .3s ease 0s;
	opacity: 0;
	transform-origin: 50% 0 0;

	background: #F8F9FB;
	border: 1px solid #B9BFC7;
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	border-radius: 50%;

	&--selected{
		opacity: 1;
		transform: scale(1) translateY(-50%);

		&:before{
			content: "";
			width: 10px;
			height: 10px;
			background: #2087D2;
			display: block;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			border-radius: 50%;
		}
	}
}