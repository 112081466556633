@import '@theme';

.CircleChart {
    position: relative;
    width: 220px;
    height: 220px;
    $line-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        border: $line-width solid #efefef;
    }

    &__line {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        background: conic-gradient(#ff6a00, #ffcc02);
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            border-radius: 50%;
            background: #fff;
            top: $line-width;
            bottom: $line-width;
            left: $line-width;
            right: $line-width;
        }
    }
    &__value {
        font-family: $ff_bold;
        color: #ff6a00;
        font-size: 60px;
        position: relative;
    }
}