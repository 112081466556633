@import '@theme';

.Tile {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 300px;
    width: $tile-width;
    height: $tile-height;
    margin-inline: $tile_half_indent;
    margin-block: 0 2 * $tile_half_indent;
    $root: &;

    &:hover {
        z-index: 2;
    }

    &.is-expanded {
        height: auto;
    }

    &.is-expand-to-top {
        flex-direction: column-reverse;
    }

    &.is-html-render {
        margin: 0;
        height: auto;
    }

    &__inner {
        height: 100%;
        background: #ffffff;
        transition: box-shadow 300ms;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);

        #{$root}:hover &,
        #{$root}.is-expanded & {
            height: auto;
        }

        #{$root}:hover & {
            box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.5);
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: red;
        height: 24px;
        width: 86px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 10px;
        font-family: $ff_medium;
        color: #ffffff;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-width: 12px 10px 12px 10px;
            border-style: solid;
            border-top-color: red;
        }

        &:before {
            right: 100%;
            border-right-color: red;
        }

        &:after {
            left: 100%;
            border-left-color: red;
        }
    }

    &__top-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #636363;
        padding: 10px 8px;
        position: relative;
        height: 38px;
        transition: padding-right 300ms;

        #{$root}.is-selectable:hover &,
        #{$root}.is-selected & {
            padding-right: 35px;
        }
    }

    &__checkbox {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        transition: all 300ms ease 0s;
        color: transparent;
        border: 2px solid #d2d2d2;
        opacity: 0;
        cursor: pointer;

        #{$root}:hover &,
        #{$root}.is-selected & {
            opacity: 1;
        }

        #{$root}.is-selected & {
            color: #ffffff;
            background-color: $green;
            border: 2px solid $green;
            opacity: 1;
        }

        * {
            pointer-events: none;
        }

        &:after {
            content: '';
            visibility: hidden;
            position: absolute;
            animation: scale_pulse 2s 1s infinite ease-out;
            top: 0;
            left: 0;
            background: transparent;
            width: 100%;
            height: 100%;
            border: 1px solid #BDBDBD;
            border-radius: 50%;
            backface-visibility: hidden;
            z-index: 1;
            pointer-events: none;

            #{$root}:hover & {
                visibility: visible;
            }
        }
    }

    &__header {
        padding: 0 8px;
    }

    &__sup-title {
        display: block;
        font-size: 13px;
        line-height: 16px;
        min-height: 16px;
        text-decoration: none;
        padding-right: 10px;
        color: #32C1E9;
        white-space: nowrap;
        cursor: pointer;
    }

    &__title {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        padding-bottom: 5px;
        font-family: $ff_medium;
        font-size: 20px;
        line-height: 24px;
        min-height: 24px;
        letter-spacing: -1px;
        color: #000000;
        margin-top: 2px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 29px;

        #{$root}:hover &,
        #{$root}.is-expanded & {
            white-space: normal;
            max-height: 100px;
            transition: max-height 500ms;
        }
    }

    &__image-container {
        position: relative;
        height: 170px;
        width: 100%;
        flex-shrink: 0;
    }

    &__image {
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
        align-items: center;
        justify-content: center;
        color: #939393;
    }

    &__menu {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background: rgba(#000, 0.56);
        opacity: 0;
        transition: opacity 300ms;

        #{$root}:hover & {
            opacity: 1;
        }
    }

    &__menu-item {
        position: relative;
        height: 32px;
        width: 180px;
        margin-block: 2px;
        border: 1px solid;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 300ms;
        cursor: pointer;
        transform: scale(1.01) translateY(-8px);
        opacity: 0;
        text-decoration: none;

        #{$root}:hover & {
            transform: scale(1) translateY(0);
            opacity: 1;
            @for $i from 1 to 4 {
                &:nth-child(#{$i}) {
                    transition: background 300ms ease 0s,
                    border-color 600ms ease 0s,
                    opacity 500ms ease #{(4 - $i) * 100}ms,
                    transform 300ms ease #{(4 - $i) * 100}ms;
                }
            }
        }

        &:hover {
            background: $green;
            border-color: $green;

            #{$root}__menu-item-hover {
                opacity: 1;
            }
        }

        &.is-loading {
            background: $green;
            border: 0;
            overflow: hidden;
            pointer-events: none;
        }

        &.is-status {
            background: #AFB5BD;
            border-color: #AFB5BD;
            text-transform: none;
            font-size: 12px;
        }
    }

    &__menu-item-hover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 300ms;
    }

    &__description {
        font-family: $ff_light;
        font-size: 15px;
        line-height: 18px;
        color: $deepblue;
        box-sizing: border-box;
        margin-top: 8px;
        padding: 0 8px;
        max-height: 72px;
        min-height: 72px;
        overflow: hidden;

        &.is-smaller {
            max-height: 54px;
            min-height: 54px;
        }

        &.is-extended {
            max-height: 180px;
            min-height: 180px;

            &.is-smaller {
                max-height: 162px;
                min-height: 162px;
            }
        }

        #{$root}:hover &,
        #{$root}.is-expanded & {
            max-height: 1000px;
            transition: max-height 1500ms;
        }

        #{$root}.is-html-render & {
            min-height: auto;

            &.is-padded {
                padding-bottom: 12px;
            }
        }
    }

    &__status {
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        background: #4A5261;
        color: #fff;
        transform: translateY(6px);
        box-shadow: 0 3px 0 0 #4A5261, 0 -3px 0 0 #4A5261;

        #{$root}.is-html-render &.is-padded {
            transform: translateY(-5px);
        }
    }

    &__access-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 15px;
        $access-overlay-root: &;

        &-top {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            #{$access-overlay-root}.is-sent & {
                margin-bottom: 0;
            }
        }
        &-text {
            flex: 0 1 100%;
            font-size: 14px;
            line-height: 14px;

            #{$access-overlay-root}.is-sent & {
                flex: 0 0 auto;
            }
        }
        &-icon {
            flex: 0 0 24px;
            height: 24px;
            margin-right: 20px;

            #{$access-overlay-root}.is-sent & {
                margin-right: 8px;
            }
        }
    }
}

.TileMetrics {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 110px;
    background-color: $deepblue;
    color: #FFFFFF;
    margin-top: 5px;
    padding-inline: 5px;
    $root: &;

    &__rows {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 8px 4px;
        position: relative;
    }

    &__row {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 16px;
        height: 16px;
        position: relative;
    }

    &__row-title {
        width: 85px;
        text-align: right;
        box-sizing: border-box;
    }

    &__row-value {
        width: 30px;
        text-align: center;
        padding-left: 5px;
    }

    &__row-rating {
        box-sizing: border-box;
        width: 70px;
    }

    &__score {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 950px;
        box-sizing: border-box;
        padding-block: 14px;
    }

    &__score-title {
        font-family: $ff_caption;
        font-size: 12px;
        line-height: 20px;
        color: $yellow;
        text-transform: uppercase;
    }

    &__score-value {
        font-family: $ff_caption;
        color: $yellow;
        font-size: 40px;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    &__score-label {
        font-family: $ff_medium;
        font-size: 11px;
        line-height: 18px;
        color: #F2F4F8;
        text-transform: lowercase;
        white-space: nowrap;
    }

    &__thumb {
        border-radius: 2px;
        z-index: 2;
        cursor: pointer;
        box-shadow: inset 0 5px 0 0 $deepblue, inset 0 -5px 0 0 $deepblue;
        transition: background-color 300ms;

        * {
            pointer-events: none;
        }

        &#{$root}__score {
            box-shadow:
                inset 0 15px 0 0 $deepblue,
                inset 0 -15px 0 0 $deepblue,
                inset 2px 0 0 0 $deepblue,
                inset -2px 0 0 0 $deepblue
            ;
        }

        &:hover {
            background: #293034;
        }
    }

    &__values {
        width: 125px;
    }

    &__stars {
        width: 75px;
    }
}

@keyframes scale_pulse {
    0% {
        transform: scale(0);
        opacity: 1;
        transform-origin: center;
    }
    100% {
        transform: scale(3);
        opacity: 0;
        transform-origin: center;
    }
}