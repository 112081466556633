
.card-brand-fit-content{
	padding: 24px; 
}

.card-brand-fit-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__description{
		text-align: right;
		font-size: 14px;
		font-family: "DIN Pro Regular";
	}
}


.card-brand-fit-legend {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	color: rgba(#4A4A4A, 0.7);

	&__value{
		font-family: 'DIN Pro Medium';
		font-size: 36px;
		line-height: 20px;
		color: #32C1E9;
	}

	&__caption{
		color: #1A2024;
		padding-left: 15px;
		line-height: 20px;

		b{
			color: #32C1E9;
		}
	}
}

.card-brand-fit-legend-column{
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 15px; 
	border-right: 2px dashed #CCCCCC;

	font-size: 13px;

	&:last-child{
		padding-left: 15px;
		padding-right: 0px;
		border-right: 0;
		flex-basis: 70%;
 
		line-height: 20px;

		white-space: nowrap;
	}
}

.card-brand-fit-chart {
	margin-top: 20px;
	margin-left: -10px;
	margin-right: -10px;
}