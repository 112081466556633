$font_normal: 'DIN Pro Regular';
$ff_normal: $font_normal, sans-serif;
$font_light: 'DIN Pro Light';
$ff_light: $font_light, sans-serif;
$font_medium: 'DIN Pro Medium';
$ff_medium: $font_medium, sans-serif;
$font_bold: 'DIN Pro Bold';
$ff_bold: $font_bold, sans-serif;
$font_caption: 'Eras Bold ITC';
$ff_caption: $font_caption, sans-serif;
$font_icons: 'icomoon';
$ff_icons: 'icomoon';

$wrapper_width: 1680px;
$wrapper_min_width: 1360px;
$header_height: 80px;
$footer_height: 60px;

$body_bg: #f8f6f6;
$lightblue: #32c1e9;
$medblue: #27a7ca;
$blue: #2087D2;
$deepblue: #141932;
$yellow: #FFCD00;
$dimyellow: #E3CB92;
$darkyellow: #FDBF2D;
$orange: #FFAA1E;
$red: #f97960;
$brown: #8f7161;
$deepgreen: #1AAF54;
$green: #8dca0f;
$purple: #c57aeb;
$gray_cl: #4a4a4a;
$gray_bg: #f2f4f8;
$gray_bd: #b9bfc7;
$header_bg: #212121;
$header_bar_bg: #2E343D;
$error: #f00;

@mixin multilines-ellipsis($lines: 3) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

@mixin media-min($media-min, $property: width) {
    @media only screen and (min-#{$property}: $media-min) {
        @content;
    }
}

@mixin media-max($media-max, $property: width) {
    @media only screen and (max-#{$property}: $media-max) {
        @content;
    }
}

$tile_half_indent: 12px;
$tile-width: 300px;
$tile-height: 450px;
$tile-with-indents-width: $tile-width + 2 * $tile_half_indent;