.BreadCrumbs {
    position: relative;
    display: flex;
    padding: 10px 0;
    align-items: center;
    color: #000;

    &__item {
        margin-right: 15px;
        position: relative;
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 22px;

        &.is-disabled {
            text-decoration: none;
            pointer-events: none;
        }

        &:last-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        &:after {
            content: '»';
            display: block;
            width: 15px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 100%;
            font-size: 15px;
            line-height: 19px;
        }

    }
}