

.card-daypart-fit-header{
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__description {
		text-align: right;
		font-size: 14px;
		font-family: "DIN Pro Regular";
	}
}

.card-daypart-fit-content{
	padding: 24px; 
}

.card-daypart-fit-chart{

}

.card-daypart-fit-legend-list {
	display: flex;
}

.card-daypart-fit-legend-item {
	display: flex;
	align-items: center;
	color: #4A4A4A;
	padding-right: 24px;
}


.card-daypart-fit-legend-marker{
	box-sizing: border-box;
	width: 24px;
	height: 1px;
	margin-right: 8px;

	&--total{
		width: 15px;
		height: 15px;
		border-radius: 50%;
		border: 2px solid #E41708; 
		background-color: #FCFF78 !important;
	}
}