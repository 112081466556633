@import '@theme';

.SelectOptionsAndColors {
    &__dropdown {
        position: absolute;
        top: 100%;
        color: #1a2024;
        padding: 0;
        border-radius: 0 0 4px 4px;
        border: 1px solid $medblue;
        box-shadow: none;
        margin-top: -2px;
    }

    &__options {
        max-height: 150px;
        overflow: auto;
        padding: 8px;
    }

    &__option {
        margin-bottom: 6px;
        &.is-disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    &__colors {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #000;
        width: 100%;
        flex-wrap: wrap;
        padding: 10px 30px;
    }

    &__color {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 4px;
        transition: transform 300ms;
        cursor: pointer;

        &:hover {
            transform: scale(1.2);
        }
    }

    .Field {
        align-items: flex-start;
    }

    .Field__label {
        font-size: 14px;
        line-height: 14px;
        margin-left: 5px;
    }

    &.is-opened .SelectCustom__thumb {
        border-radius: 4px 4px 0 0;
        border-left-color: $medblue;
        border-right-color: $medblue;
        border-bottom-color: $medblue;
    }
}