@import '@theme';

.FiltersButton {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background-color: $blue;
    color: transparent;
    -webkit-background-clip: text;
    animation: filter-button-gradient 2.5s linear infinite;

    &.is-active {
        background-image: linear-gradient(#32C1E9, #4788f2, #6631FE, #4788f2, #32C1E9);
        background-size: 100% 100px;
    }

    * {
        fill: currentColor;
    }

    &__button {
        display: flex;
        align-items: center;
        font-size: 32px;
        line-height: 32px;
        cursor: pointer;
        user-select: none;

        &:before {
            content: "\e947";
            font-family: $ff_icons;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            width: 36px;
            height: 36px;
            margin-right: 10px;
            -webkit-font-smoothing: antialiased;
        }
    }

    &__message {
        font-size: 14px;
        text-transform: none;
        margin-left: 10px;
        white-space: nowrap;
    }
}

@keyframes filter-button-gradient {
    from {
        background-position-y: 0;
    }
    to {
        background-position-y: 100px;
    }
}