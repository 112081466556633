@import '@theme';

.Field {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    $root: &;

    &.is-inline {
        flex-direction: row;
        align-items: center;
        padding-bottom: 0;

        #{$root}__label {
            flex: 0 1 100%;
            margin-bottom: 0;
        }
        #{$root}__content {
            flex: 0 0;
        }
    }

    &__label {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex: 0 0;
    }

    &__options {
        display: flex;
        font-size: 14px;
    }

    &__content {
        flex: 1 0 100%;
        #{$root}__label {
            font-size: 13px;
            font-family: $ff_medium;
        }
    }
}