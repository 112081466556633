@import '@theme';

.VersusBarChart {
    $content-height: 140px;
    $alt-color: #4A5261;
    padding: 0 10px;

    &__legend {
        display: flex;
    }

    &__legend-button {
        background: #f3f3f3;
        margin-right: 20px;
        height: 40px;
    }

    &__legend-color {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        border-radius: 50%;

        &.is-main {
            background-image: linear-gradient(to top, #ffcc02, #ff6a00);
        }

        &.is-compare {
            background-color: $alt-color;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 80px;
    }

    &__item {
        height: $content-height;
        position: relative;
        display: flex;
        align-items: flex-end;
        flex: 0 1 100%;
        justify-content: center;
        transition: background-color 300ms, filter 300ms, opacity 300ms;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% + 1px);
            height: 1px;
            background: #aaa;
        }

        &:before {
            content: '';
            position: absolute;
            pointer-events: none;
            display: block;
            top: -30px;
            left: 0;
            right: 0;
            bottom: -95px;
            background: rgba(#000, 0.05);
            opacity: 0;
        }

        &.is-hoverable:hover {
            &:before {
                transition: opacity 300ms;
                opacity: 1;
            }
        }

        &.is-inactive {
            filter: grayscale(1);
            opacity: 0.5;

            &:hover {
                filter: grayscale(0);
            }
        }

        &.is-clickable {
            cursor: pointer;
        }
    }

    &__bar {
        border-radius: 4px 4px 0 0;
        width: 20px;
        background-image: linear-gradient(to top, #ffcc02, #ff6a00);
        background-size: 100% $content-height;
    }

    &__compare-bar {
        position: absolute;
        width: 24px;
        height: 6px;
        border-radius: 3px;
        background: $alt-color;
        left: 50%;
        transform: translateX(-50%);
    }

    &__bar-label {
        $width: 90px;
        $height: 30px;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        width: $width;
        height: $height;
        padding-right: 10px;

        position: absolute;
        top: calc(100% + #{0.5 * ($width - $height)});
        left: 50%;
        margin-left: - 0.5 * $width;
        transform-origin: 50% 50%;
        transform: rotate(-90deg);

        font-size: 12px;
        line-height: 12px;
        text-align: right;
    }

    &__value-label {
        z-index: 2;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        font-size: 14px;
        line-height: 20px;
        font-family: $ff_medium;
        padding-bottom: 5px;
        text-shadow:
            1px 1px 0.9px #fff, -1px -1px 0.9px #fff, 1px -1px 0.9px #fff, -1px 1px 0.9px #fff,
            1px 0 0.9px #fff, -1px 0 0.9px #fff, 0 1px 0.9px #fff, 0 -1px 0.9px #fff,
            2px 0 2px rgba(#fff, 0.8), -2px 0 2px rgba(#fff, 0.8), 0 2px 2px rgba(#fff, 0.8), 0 -2px 2px rgba(#fff, 0.8),
        ;
        display: flex;
        justify-content: center;
    }

    &__tooltip-title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
        font-family: $ff_medium;
        text-align: center;
    }
    &__tooltip-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        direction: ltr;
    }

    &__tooltip-color {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 10px;
        &.is-main {
            background-image: linear-gradient(to top, #ffcc02, #ff6a00);
        }

        &.is-compare {
            background-color: $alt-color;
        }
    }

    &__tooltip-row-value {
        width: 30px;
        margin-left: 10px;
        text-align: left;
    }
}