@import '@theme';

.RangeSlider {
    height: 44px;
    display: flex;
    align-items: center;

    .slider {
        width: 100%;
    }

    &__track {
        height: 2px;
        top: 50%;
        margin-top: -1px;
        background-color: #a2a1a1;

        &.track-1 {
            height: 4px;
            margin-top: -2px;
            background-color: $lightblue;
        }

    }
    
    &__thumb {
        height: 12px;
        width: 12px;
        margin-top: -6px;
        top: 50%;
        border-radius: 50%;
        cursor: pointer;
    }

    &__thumb-circle {
        width: 100%;
        height: 100%;
        border: 1px solid $lightblue;
        background-color: #fff;
        border-radius: 50%;

        &:hover {
            transform: scale(1.1);
        }
    }

    &__thumb-value {
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
    }
}