@import "@theme";

.BarChart {
    display: flex;
    flex-direction: column;
    $line-thick: 6px;
    font-size: 14px;
    $root: &;

    &__items {
        position: relative;
        &.is-stretch {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item-title {
        flex: 0 0 25%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
    }

    &__item-value-bar {
        flex: 0 1 100%;
        height: $line-thick;
        border-radius: $line-thick * 0.5;
        background: #efefef;
        position: relative;
    }

    &__item-bar {
        overflow: hidden;
        height: 100%;
        border-radius: $line-thick * 0.5;
        background-image: linear-gradient(to left, #ff6a00, #ffcc02);
    }

    &__item-value {
        flex: 0 0 50px;
        text-align: right;
    }

    &__norm {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        pointer-events: none;

        & > * {
            height: 100%;
            background-color: transparent;
        }
    }

    &__norm-value {
        position: absolute;
        top: 0;
        height: 100%;
        width: 1px;
        background: #cecece;
    }

    &__norm-label {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 5px);
        padding: 5px 10px;
        border-radius: 4px;
        background: #efefef;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }
}