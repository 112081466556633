@import '@theme';

.Button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 33px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    border: none;

    text-decoration: none;
    user-select: none;
    cursor: pointer;
    background-color: #fff;
    font-family: $ff_normal;
    font-style: normal;
    font-size: 14px;
    color: #000;
    white-space: nowrap;
    text-align: center;

    transition: background-color .3s,color .3s,box-shadow .2s;

    * {
        pointer-events: none;
    }

    &:hover {
        box-shadow: inset 0 0 0 1000px rgba(#000, 0.14);
    }

    &.is-no-click {
        cursor: default;
        box-shadow: none !important;
    }

    &.h-tall {
        height: 40px;
    }

    &.is-disabled {
        filter: grayscale(1);
        cursor: default;
    }

    &.orange {
        background-color: $orange;
        color: #fff;

        &.naked {
            background-color: transparent;
            color: $orange;
            &:hover {
                box-shadow: inset 0 0 0 1000px rgba($orange, 0.14);
            }
        }
    }

    &.blue {
        background-color: $lightblue;
        color: #fff;

        &.naked {
            background-color: transparent;
            color: $medblue;
            &:hover {
                box-shadow: inset 0 0 0 1000px rgba($medblue, 0.14);
            }
        }
    }

    &.green {
        background-color: $green;
        color: #fff;
        &.naked {
            background-color: transparent;
            color: $green;
            &:hover {
                box-shadow: inset 0 0 0 1000px rgba($green, 0.14);
            }
        }
    }

    &.gray {
        background-color: $gray_bd;
        color: #fff;

        &.naked {
            background-color: transparent;
            color: $gray_cl;

            &:hover {
                box-shadow: inset 0 0 0 1000px rgba($gray_cl, 0.14);
            }
        }
    }

    &.black {
        background-color: lighten($header_bg, 20%);
        color: #fff;

        &.naked {
            background-color: transparent;
            color: lighten($header_bg, 20%);

            &:hover {
                box-shadow: inset 0 0 0 1000px rgba($header_bg, 0.14);
            }
        }
    }

    &.red {
        background-color: $red;
        color: #fff;

        &.naked {
            background-color: transparent;
            color: $red;
            &:hover {
                box-shadow: inset 0 0 0 1000px rgba($red, 0.14);
            }
        }
    }

    &.lightgray {
        background: $gray_bg;
    }

    &.fw-bold {
        font-family: $ff_bold;
    }
}