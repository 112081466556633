@import '@theme';

.TextInput {
    display: block;
    width: 200px;
    font-size: 14px;
    font-family: $ff_normal;
    color: #4a4a4a;
    box-sizing: border-box;
    transition: all .150s ease;
    -webkit-appearance: none;
    appearance: none;
    background: #ffffff;
    border: solid 1px #B9BFC7;
    padding: 0 10px;
    outline: none;
    height: 32px;
    border-radius: 4px;
}