$light-color: #eae8e8;
$dark-color: #e5e3e3;
$background-color: #f8f6f6;


@keyframes content-loader-animate {
	0% {
		transform: translateX(-100%) translateZ(0);
	}
	100% {
		transform: translateX(100%) translateZ(0);
	}
}

.content-loader {
	overflow: hidden;
}

.content-loader-block {
	height: 15px;
	margin-top: 10px;
	background-color: $dark-color;
	position: relative;


	&__front {
		background-color: $dark-color;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		animation-duration: 1s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: content-loader-animate;
		animation-timing-function: linear;
		background: linear-gradient(
			to right,
			$dark-color 8%,
			$light-color 18%,
			$dark-color 33%
		);
	}

	&__back{
		position: absolute;
		background-color: $background-color;
		right: 0;
		top: 0;
		height: 100%;
		width: 0;
	}

	&--one {
		margin-top: 0;
		.content-loader-block__back {
			width: 15%;
		}
	}

	&--two {
		.content-loader-block__back {
			width: 1%;
		}
	}

	&--three {
		.content-loader-block__back {
			width: 28%;
		}
	}

	&--four {
		.content-loader-block__back {
			width: 10%;
		}
	}

	&--five {
		.content-loader-block__back {
			width: 60%;
		}
	}
}