@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;

@import '@theme';

.flex-col {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	padding-right: 8px;
	padding-left: 8px;
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
}

.flex-col-12 {
	width: 100%;
	padding-inline: 8px;
}

.flex-col-6 {
	flex-basis: 50%;
	max-width: 50%;
	padding-inline: 8px;
}


.item-details{
	font-family: $ff_normal;

	&__header {
		height: 0;
		position: sticky;
		top: calc($platform-navigation-height + $platform-header-height);
		z-index: 3;
	}

	&__header-sizer {
		opacity: 0;
		pointer-events: none;
	}
}

.flex-row {
	margin-left: -8px;
	margin-right: -8px;
}

.flex-row-details{
	margin-top: 24px;
	display: flex;

    &--main{
        flex-wrap: nowrap;
    }
}

.flex-col-details{
	display: flex;

	&__card{
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;
	}

    &--composite-score{
        flex-basis: 700px;
        max-width: 700px;
    }
}
