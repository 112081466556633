@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import "@theme";

.ChainsChart {
    position: relative;
    min-height: calc(100vh - 140px);
    padding-bottom: 60px;

    &__header-bar {
        margin-bottom: 20px;
        position: sticky;
        top: calc($platform-navigation-height + $platform-header-height);
        z-index: 4;
    }

    &__content {
        display: flex;
        align-items: flex-start;
    }

    &__chart {
        width: calc(100% - 320px);
        position: relative;
    }

    &__sidebar {
        position: relative;
        width: 280px;
        margin-left: 40px;
    }
}

.ChainsChartSidebar {
    &__header {
        padding: 30px 16px;
        background: #f2f4f8;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__row {
        width: 100%;
        position: relative;
        margin-top: 8px;
        padding: 8px;
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }

    &__row-header {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-family: $ff_medium;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        align-items: center;
        letter-spacing: -1px;
        text-transform: uppercase;
        border-bottom: 2px solid #000000;
        box-sizing: border-box;
    }

    &__metrics {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: #1A2024;
        margin-top: 8px;
        line-height: 13px;
    }

    &__metric {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 120px;
    }

    &__metric-value {
        font-family: $ff_caption;
        color: yellow;
        font-weight: bold;
        font-size: 28px;
        line-height: 20px;
        margin-top: 8px;
    }
}