@import '@theme';


.LockAccessOverlay {
    z-index: 3;
    position: absolute;
    top: -5px;
    bottom: -5px;
    right: -5px;
    left: -5px;
    background: rgba(#000, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $header_bar_bg;
        padding: 30px;
        border-radius: 8px;
        min-height: 200px;
        min-width: 300px;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }

    &__title {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
    }

    &__sub-title {
        font-size: 12px;
        line-height: 16px;
        margin-top: 5px;
        opacity: 0.7;
        text-align: center;
    }

    &__button {
        margin-top: 20px;
    }
}