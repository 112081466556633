@import '@theme';

.StackedPercentChart {
    position: relative;
    padding: 15px;
    $slice_title_width: 100px;

    &__legend {
        display: flex;
        padding-left: $slice_title_width;
        justify-content: space-between;
        padding-right: 20px;
        margin-bottom: 20px;
    }

    &__legend-button {
        background: #f3f3f3;
        flex: 0 1 100%;
        margin-right: 20px;
        height: 40px;
        padding: 0 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__legend-color {
        width: 16px;
        height: 16px;
        border-radius: 10px;
        margin-right: 10px;
    }

    &__content {
        position: relative;
        padding-right: 10px;
    }

    &__slice {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
    }

    &__slice-title {
        flex: 0 0 $slice_title_width;
        font-size: 16px;
        font-family: $ff_medium;
        text-align: center;
    }

    &__slice-values {
        display: flex;
        flex: 0 1 100%;
        height: 32px;
    }

    &__slice-value {
        color: #fff;
        flex: 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: $ff_medium;

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &:first-child:last-child {
            border-radius: 4px;
        }
    }
}