@import '@theme';

.ChainProfile {
    min-height: calc(100vh - 140px);
    padding-bottom: 60px;

    .card {
        margin-bottom: 20px;
    }

    .card-header {
        padding: 15px;
        min-height: 50px;
        font-size: 15px;
    }
}

.ChainProfileComparisonTable {
    .Table {
        padding: 4px;
        min-width: 100%;
        padding-bottom: 14px;
    }
    .TableHead {
        height: auto;

        .TableCell {
            padding-block: 5px;
            padding-inline: 16px;
            min-height: 35px;
            height: auto;
        }
    }
    .TableRow {
        min-height: 30px;
        height: auto;

        &.total-row {
            font-family: $ff_medium;

            .TableCell {
                box-shadow: inset 0 0 0 1000px rgba(#000, 0.06);
            }
            &:after {
                box-shadow: inset 0 0 0 1000px rgba(#000, 0.06);
            }
        }
        .TableCell {
            height: auto;
            padding-block: 5px;
        }
    }

    .TableCell {
        flex: 0 0 120px;

        &:first-child {
            background-color: #fff;
            flex: 0 0 220px;
            position: sticky;
            left: 4px;
            z-index: 2;

            &:before {
                content: '';
                display: block;
                position: absolute;
                right: 100%;
                top: 0;
                bottom: 0;
                width: 4px;
                background: #fff;
            }
        }

        &:nth-child(2) {
            background: #fffae5;
            position: sticky;
            left: 224px;
            z-index: 2;
        }

        &:nth-child(3) {
            background: #f6f6f7;
            position: sticky;
            left: 344px;
            z-index: 2;
        }

        &.center {
            justify-content: center;
            text-align: center;
        }
    }
}

.ChainComparisonChart {
    &__legend {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 400px;
        height: 300px;
        padding: 40px 0 40px 30px;
        border-left: 1px solid #ddd;
        margin-left: 20px;
        font-size: 20px;
        line-height: 24px;
    }

    &__legend-item {
        display: flex;
        align-items: center;
        margin-top: 25px;
        font-size: 16px;
        line-height: 20px;
    }
    &__legend-count {
        flex: 0 0 90px;
        width: 90px;
        height: 65px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 26px;
        margin-right: 10px;
        font-family: $ff_medium;

        &.is-main {
            background-image: linear-gradient(to top, #ffcc02, #ff6a00);
        }

        &.is-compare {
            background-color: #4A5261;
        }
    }
}